<template>
    <div class="feature-project-2" v-inview:enter="animateIn">
        <div class="feature-project-2__container">
            <h2 ref="title" class="feature-project-2__title t-heading" v-html="title"></h2>

            <div class="feature-project-2__content">
                <div ref="left" class="feature-project-2__left">
                    <div class="feature-project-2__numbers">
                        <NumbersBlock ref="numbers" :isInView="isInView" :content="content.numbers" />
                    </div>
                    <div ref="imgOneContainer" class="feature-project-2__image-left" v-if="content.images[1]">
                        <img ref="imgOne" class="feature-project-2__image" :src="imageLoader(content.images[1], { width: 413, height: 413, fit: true })" alt="development feature image">
                    </div>
                </div>

                <div ref="right" class="feature-project-2__right">
                    <div ref="imgTwoContainer" class="feature-project-2__image-right" v-if="content.images[0]">
                        <img ref="imgTwo" class="feature-project-2__image" :src="imageLoader(content.images[0], { width: 586 })" alt="development feature image">
                    </div>
                    <div class="feature-project-2__numbers-mobile">
                        <NumbersBlock :isInView="isInView" :content="content.numbers" />
                    </div>
                    <div ref="paragraph" class="feature-project-2__paragraph">
                        <div v-if="content.overviewIntro" class="paragraph t-copy" v-html="content.overviewIntro" />
                        <router-link :to="content.slug" class="t-copy paragraph__button u-text-link" v-html="general.link_labels.more"></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap';
import { mapGetters } from 'vuex';
import NumbersBlock from '../components/NumbersBlock.vue';
import FeatureParagraph from '../components/FeatureParagraph.vue';
import OnScroll from '../utils/OnScroll';
import { SplitText } from '../utils/SplitText';
import imageLoader from '../utils/imageLoader';

export default {
    name: 'FeaturedProject2',

    components: {
        NumbersBlock,
        FeatureParagraph
    },

    props: {
        content: Object
    },

    data() {
        return {
            isInView: false,
            scrolls: [],
            imageLoader
        };
    },

    computed: {
        ...mapGetters(['isMobile', 'general']),

        title() {
            return `${ this.content.title }&nbsp;—<br />${ this.content.city } `;
        },
    },

    watch: {
        isMobile() {
            this.reset();
        }
    },

    mounted() {
        this.prepareElements();
    },

    beforeDestroy() {
        this.scrolls.forEach((scroll) => {
            scroll.kill();
        });
    },

    methods: {
        reset() {
            if (this.isMobile) {
                this.scrolls.forEach((scroll) => {
                    scroll.kill();
                });

                this.scrolls = [];

                gsap.set([
                    this.$refs.imgOne,
                    this.$refs.imgTwo,
                    this.$refs.imgOneContainer,
                    this.$refs.imgTwoContainer,
                    this.$refs.numbers.$el,
                    this.$refs.title,
                    this.$refs.paragraph.$el,
                    this.$refs.right
                ], {
                    clearProps: 'all'
                });

                if (this.split) {
                    this.split.revert();
                }

                if (this.$titleChars) {
                    gsap.to(this.$titleChars, {
                        duration: .7,
                        opacity: 1,
                        ease: 'power3.inOut',
                        stagger: {
                            each: 0.02,
                            ease: 'none'
                        }
                    });
                }
            } else {
                if (this.split) {
                    this.split.revert();
                }

                this.prepareElements();

                if (this.$titleChars) {
                    gsap.to(this.$titleChars, {
                        duration: .7,
                        opacity: 1,
                        ease: 'power3.inOut',
                        stagger: {
                            each: 0.02,
                            ease: 'none'
                        }
                    });
                }
            }
        },

        prepareElements() {
            if (this.isMobile) return;

            this.split = new SplitText(this.$refs.title, { type: 'chars', charsClass: 'split' });
            this.$titleChars = this.split.chars;

            // title
            gsap.set(this.$titleChars, {
                opacity: 0,
            });

            // paralax once
            gsap.set([this.$refs.imgOne, this.$refs.imgTwo], {
                opacity: 0,
                scale: 0.8,
                transformOrigin: 'center'
            });

            this.scrolls.push(new OnScroll(this.$refs.imgOne, {
                opacity: 1,
                scale: 1
            }, 'A', true));

            this.scrolls.push(new OnScroll(this.$refs.imgTwo, {
                opacity: 1,
                scale: 1
            }, 'A', true));

            // paralax always on
            gsap.set([
                this.$refs.imgOneContainer,
                this.$refs.imgTwoContainer,
                this.$refs.numbers.$el,
                this.$refs.title,
                this.$refs.paragraph.$el
            ], {
                y: 100,
                transformOrigin: 'center'
            });

            this.scrolls.push(new OnScroll(this.$refs.imgOneContainer, {
                y: 0
            }, 'A'));

            this.scrolls.push(new OnScroll(this.$refs.imgTwoContainer, {
                y: 0
            }, 'A'));

            this.scrolls.push(new OnScroll(this.$refs.numbers.$el, {
                y: 0
            }, 'A'));

            this.scrolls.push(new OnScroll(this.$refs.title, {
                y: 0,
            }, 'A'));

            this.scrolls.push(new OnScroll(this.$refs.paragraph.$el, {
                y: 0,
            }, 'A'));

            this.scrolls.push(new OnScroll(this.$refs.right, {
                y: -110,
            }, 'C'));
        },

        animateIn() {
            this.isInView = true;

            gsap.to(this.$titleChars, {
                duration: .5,
                opacity: 1,
                ease: 'none',
                stagger: {
                    each: 0.025,
                    ease: 'none'
                }
            });
        }
    }
};
</script>
