export default class SimpleSwipeController {
    constructor(options) {
        this.$el = options.$el;
        this.trigger = options.trigger;
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
    }

    initialize() {
        this.$el.addEventListener('touchstart', this.onTouchStart);
        this.$el.addEventListener('mousedown', this.onTouchStart);
        this.$el.addEventListener('touchend', this.onTouchEnd);
        this.$el.addEventListener('mouseup', this.onTouchEnd);
    }

    terminate() {
        this.$el.removeEventListener('touchstart', this.onTouchStart);
        this.$el.removeEventListener('mousedown', this.onTouchStart);
        this.$el.removeEventListener('touchend', this.onTouchEnd);
        this.$el.removeEventListener('mouseup', this.onTouchEnd);
    }

    onTouchStart(e) {
        this.$el.addEventListener('touchmove', this.onTouchMove);
        this.$el.addEventListener('mousemove', this.onTouchMove);

        const { x, y } = this.getPosition(e);

        this.initialX = x;
        this.initialY = y;

        this.lastX = x;
        this.lastY = y;
    }

    onTouchMove(e) {
        const { x, y } = this.getPosition(e);

        const dX = this.lastX - x;
        const dY = this.lastY - y;

        if (Math.abs(dX) > Math.abs(dY)) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.lastX = x;
        this.lastY = y;
    }

    onTouchEnd(e) {
        this.$el.removeEventListener('touchmove', this.onTouchMove);
        this.$el.removeEventListener('mousemove', this.onTouchMove);

        const { x, y } = this.getPosition(e);

        const lastX = x;
        const lastY = y;

        const dX = Math.abs(lastX - this.initialX);
        const dY = Math.abs(lastY - this.initialY);

        if (dY > dX || dX < 50) return false;

        const direction = lastX < this.initialX ? -1 : 1;

        return this.trigger(direction);
    }

    getPosition(e) {
        if (e.type.match(/(mousedown|mousemove|mouseup)/)) return { x: e.pageX, y: e.pageY };
        return { x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY };
    }
}
