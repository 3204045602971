<template>
    <nav class="nav-mobile" :class="{ 'nav-mobile--opened': isOpened }">
        <div class="nav-mobile__page">
            <transition-group name="fade-nav" appear mode="out-in">
                <button v-if="showNav" ref="burger" class="nav-mobile__burger" :class="{ 'nav-mobile__burger--white': isHoveringBlack }" @click="onBurgerClick" key="0">
                    <svg height="23" viewBox="0 0 28 23" width="28" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="square" stroke-width="2" class="nav-mobile__burgerContainer">
                            <path ref="topBar" d="m1.448276 1.5h25.103448"/>
                            <path ref="middleBar" d="m1.448276 11.5h25.103448"/>
                            <path ref="bottomBar" d="m1.448276 21.5h25.103448"/>
                        </g>
                    </svg>
                </button>

                <router-link ref="close" v-else to="/" class="t-copy nav-mobile__close" :class="{ 'nav-mobile__close--white': isHoveringBlack }" v-html="general.mobileNav.close" key="1"></router-link>
            </transition-group>
        </div>

        <transition name="fade" appear mode="out-in">
            <div class="nav-mobile__overlay" v-if="isActive">
                <div class="nav-mobile__container">
                    <button class="nav-mobile__close t-copy" @click="onCloseClick" v-html="general.mobileNav.close"></button>

                    <div class="nav-mobile__body">
                        <ul class="nav-mobile__menu">
                            <li class="nav-mobile__item" v-for="(item, key) in general.menu" :key="key">
                                <router-link class="t-nav" :to="{ name: 'home', hash: item.target }" v-html="item.copy" @click.native="onClick(key)"></router-link>
                            </li>
                        </ul>

                        <div class="nav-mobile__social">
                            <!-- <a class="nav-mobile__socialItem" href="https://instagram.com" target="_blank">
                                <svg enable-background="new 0 0 24 25" version="1.1" viewBox="0 0 24 25" width="25" height="25" xmlns="http://www.w3.org/2000/svg">
                                    <g transform="translate(-78 -279)">
                                        <g transform="translate(78 278)">
                                            <g transform="translate(-.000821 1.4746)">
                                                <path d="m18.2 4.1c-0.8 0-1.4 0.6-1.4 1.4s0.6 1.4 1.4 1.4 1.4-0.6 1.4-1.4-0.6-1.4-1.4-1.4m-5.6 11.6c-2.8 0.5-5.2-1.9-4.6-4.6 0.3-1.6 1.5-2.8 3.1-3.1 2.8-0.5 5.2 1.9 4.7 4.6-0.4 1.6-1.6 2.8-3.2 3.1m0.2-9.9c-4.1-0.6-7.6 2.9-7 7 0.4 2.6 2.5 4.7 5 5 4.1 0.6 7.6-2.9 7-7-0.3-2.5-2.4-4.6-5-5m-1-5.8c-3.2 0-3.6 0-4.8 0.1-3.8 0.1-6.8 3.1-6.9 6.9-0.1 1.2-0.1 1.6-0.1 4.8s0 3.6 0.1 4.9c0.2 3.7 3.2 6.7 6.9 6.9 1.3 0.1 1.7 0.1 4.9 0.1s3.6 0 4.9-0.1c3.7-0.2 6.7-3.2 6.9-6.9 0.1-1.3 0.1-1.7 0.1-4.9s0-3.6-0.1-4.9c-0.2-3.7-3.2-6.7-6.9-6.9h-5m0 2.1c3.2 0 3.5 0 4.8 0.1 3.2 0.1 4.7 1.7 4.9 4.9 0.1 1.2 0.1 1.6 0.1 4.8s0 3.5-0.1 4.8c-0.1 3.2-1.6 4.7-4.9 4.9-1.2 0.1-1.6 0.1-4.8 0.1s-3.5 0-4.8-0.1c-3.2-0.1-4.7-1.7-4.9-4.9-0.1-1.2-0.1-1.6-0.1-4.8s0-3.5 0.1-4.8c0.1-3.2 1.6-4.7 4.9-4.9 1.3-0.1 1.7-0.1 4.8-0.1" fill="#fff"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </a> -->
                            <a class="nav-mobile__socialItem" href="https://www.linkedin.com/company/flowrealestate/" target="_blank">
                                <svg version="1.1" viewBox="0 0 25 25" width="25" height="25" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <defs>
                                        <polygon id="a" points="1.186 1.3605 24.878 1.3605 24.878 25.053 1.186 25.053"/>
                                    </defs>
                                    <g fill="none" fill-rule="evenodd">
                                        <g transform="translate(-130 -279)">
                                            <g transform="translate(78 278)">
                                                <g transform="translate(51.737 .11303)">
                                                <mask id="b" fill="white">
                                                    <use xlink:href="#a"/>
                                                </mask>
                                                <path d="m20.929 20.116h-2.9606v-5.5322c0-3.3246-3.9495-3.0726-3.9495 0v5.5322h-2.9606v-10.858h2.9606v1.7419c1.3794-2.5524 6.9101-2.7411 6.9101 2.4448v6.6714zm-13.327-12.111c-0.95347 0-1.7272-0.77811-1.7272-1.7404s0.77368-1.7419 1.7272-1.7419c0.95347 0 1.7272 0.77958 1.7272 1.7419s-0.77221 1.7404-1.7272 1.7404zm-1.4811 12.111h2.9621v-10.858h-2.9621v10.858zm13.822-18.756h-13.822c-2.7248 0-4.9354 2.2091-4.9354 4.9354v13.82c0 2.7278 2.2105 4.9368 4.9354 4.9368h13.822c2.7248 0 4.9354-2.2091 4.9354-4.9368v-13.82c0-2.7263-2.2105-4.9354-4.9354-4.9354z" fill="#fff" mask="url(#b)"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                            <!-- <a class="nav-mobile__socialItem" href="https://twitter.com" target="_blank">
                                <svg version="1.1" viewBox="0 0 24 20" width="25" height="21" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" fill-rule="evenodd">
                                        <g transform="translate(-184 -281)" fill="#fff">
                                            <g transform="translate(78 278)">
                                                <g transform="translate(105.85 3.6932)">
                                                    <path d="m23.692 2.2783c-0.87242 0.38758-1.8097 0.64695-2.7926 0.76632 1.0051-0.60274 1.7758-1.5547 2.1383-2.6895-0.93874 0.55558-1.9792 0.96232-3.0874 1.1789-0.88568-0.94463-2.1501-1.5341-3.5472-1.5341-3.1389 0-5.4453 2.9267-4.7364 5.9669-4.0379-0.20189-7.6204-2.1368-10.018-5.0768-1.2733 2.184-0.66021 5.0415 1.5046 6.4886-0.79579-0.026526-1.5459-0.24316-2.2002-0.60863-0.053053 2.2518 1.5606 4.3592 3.8979 4.8278-0.68526 0.18568-1.4339 0.22842-2.1958 0.082526 0.61895 1.9305 2.4124 3.3364 4.5419 3.3762-2.044 1.6019-4.6185 2.3166-7.1975 2.0131 2.1516 1.3794 4.7069 2.184 7.4509 2.184 9.0248 0 14.124-7.6219 13.816-14.458 0.95053-0.68674 1.7743-1.5429 2.4257-2.5171"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </a> -->
                        </div>

                        <p class="nav-mobile__address t-copy t-white" v-html="addressHtml"></p>
                    </div>

                    <div class="nav-mobile__footer t-nav-legal ">
                        <p class="nav-mobile__legal">© {{ new Date().getFullYear() }} <span v-html="general.rights"></span></p>
                        <span class="t-credit" v-if="isMobile || isTablet">|</span>
                        <a target="_blank" href="/privacy_policy.pdf" class="nav-mobile__disclaimer" v-html="general.disclaimer"></a>
                    </div>
                </div>
            </div>
        </transition>
    </nav>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import gsap from 'gsap';
import EventBus from '../EventBus';

export default {
    name: 'NavMobile',

    data() {
        return {
            isActive: false,
            isOpened: false,
            isHoveringBlack: false
        };
    },

    computed: {
        ...mapGetters(['isMobile', 'isTablet', 'general', 'pages', 'projects']),

        showNav() {
            return this.$route.name === 'home';
        },

        addressHtml() {
            return this.general.address.join('<br>');
        }
    },

    watch: {
        isMobile() {
            this.registerTimelines();
        }
    },

    created() {
        this.setLogoIsIntro(false);
    },

    mounted() {
        this.registerTimelines();
        this.setIntroStarted(true);
    },

    methods: {
        ...mapActions(['setIntroStarted', 'setLogoIsIntro']),

        registerTimelines() {
            if (this.$route.name !== 'home') return;
            this.tlBurger = gsap.timeline({
                paused: true,
                onComplete: () => {
                    this.isAnimating = false;
                },
                onReverseComplete: () => {
                    this.isAnimating = false;
                }
            })
            .to([this.$refs.topBar, this.$refs.middleBar, this.$refs.bottomBar], {
                duration: 0.4,
                stagger: 0.08,
                xPercent: 110,
                ease: 'power4.inOut'
            });
        },

        onClick(key) {
            this.active = key;
            this.isActive = false;
            gsap.delayedCall(.33, () => {
                this.isOpened = false;
                this.tlBurger.reverse();
            });
        },

        onBurgerClick() {
            this.tlBurger.play();
            gsap.delayedCall(.4, () => {
                this.isActive = true;
                this.isOpened = true;
            });
        },

        onCloseClick() {
            this.isActive = false;
            gsap.delayedCall(.33, () => {
                this.isOpened = false;
                this.tlBurger.reverse();
            });
        }
    }
};
</script>
