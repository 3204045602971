<template>
    <span>
        <LogoRealEstate v-if="showLogoRealEstate" />
        <LogoDevelopment v-if="showLogoDevelopment" />
    </span>
</template>

<script>

import { mapGetters } from 'vuex';
import gsap from 'gsap';
import LogoRealEstate from './LogoRealEstate.vue';
import LogoDevelopment from './LogoDevelopment.vue';

export default {
    name: 'Logo',

    components: {
        LogoRealEstate,
        LogoDevelopment,
    },

    data() {
        return {
            mobileUpdated: false,
            showLogoIntro: false,
            showLogoRealEstate: false,
            showLogoDevelopment: false
        };
    },

    computed: {
        ...mapGetters(['isMobile'])
    },

    watch: {
        $route() {
            this.selectLogo();
        },

        isMobile() {
            this.mobileUpdated = true;
            this.selectLogo();
        }
    },

    mounted() {
        const delay = window.Application.routerUpdated ? .4 : 0;
        gsap.delayedCall(delay, this.selectLogo);
    },

    methods: {
        selectLogo() {
            const isHome = this.$route.name === 'home';
            const isDeveloment = this.$route.name === 'development';
            const isInvestment = this.$route.name === 'investment';
            const isUpdated = !window.Application.routerUpdated && !this.mobileUpdated;

            // home and first load
            this.showLogoIntro = isHome && isUpdated && !this.isMobile;

            // home and not first load, or home and mobile, or investment
            this.showLogoRealEstate = (isHome && !isUpdated) || (isHome && this.isMobile) || isInvestment;

            // not home and development project
            this.showLogoDevelopment = isDeveloment;
        }
    }
};
</script>
