// Polyfills
import 'es6-promise/auto';
import '@babel/polyfill';

// Load dependencies
import gsap from 'gsap';
import Vue from 'vue';
import axios from 'axios';

import App from './App.vue';
import router from './router';
import store from './store';
import Inview from './directives/InView';

// Load style
// import './style';

/**
 *
 *  Application Start
 *
 */
class Application {
    init(options) {
        console.debug('[app] init', options);

        // Options
        this.options = options || {};

        // Viewport
        this.wH = window.innerHeight;
        this.wW = window.innerWidth;
        this.mobileBreakpoint = 750;
        this.tabletBreakpoint = 1005;
        this.isRetina = window.devicePixelRatio > 1;
        this.scroll = 0;
        window.history.scrollRestoration = 'manual';

        // Events
        this.touch = (('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0));

        // Analytics
        this.analyticsId = options.analyticsId;

        // Store
        const injectedStore = {
            windowWidth: window.innerWidth,
            isRetina: this.isRetina,
            googleMapsKey: this.options.googleMapsKey,
            general: { ...this.options.data.general },
            pages: {
                ...this.options.data.pages
            }
        };

        // ready
        this.run(injectedStore);
    }

    run(injectedStore) {
        Vue.use(Inview);
        // Create Vue instance and mount
        this.app = new Vue({
            router: router({ analyticsId: this.analyticsId }),
            store: store(injectedStore),
            ...App
        }).$mount('#app');

        console.debug('[app] run', this.app);
    }
}

window.Application = new Application();
