<template>
    <section class="contact" v-inview:enter="animateIn">
        <div class="contact__container">
            <h2 ref="title" class="contact__title t-heading" v-html="content.title"></h2>
            <p class="contact__address t-copy" v-html="addressHtml"></p>
            <a v-if="isMobile" class="contact__directions t-copy" :href="content.mapLink" target="_blank" v-html="content.directions"></a>
            <a v-else class="contact__mail t-copy t-black" :href="`mailto:${ content.mail }`" v-html="content.mail"></a>

            <form class="contact__form" :class="{ 'contact__form--loading': loading, 'contact__form--sent': sent }" ref="form">
                <div
                    ref="input"
                    class="contact__field"
                    :class="{ 'contact__field--hasError': content.form.inputs[key].hasError, 'contact__field--hasSucess': content.form.inputs[key].hasSucess }"
                    v-for="(field, key) in content.form.inputs"
                    :key="key">

                    <label class="contact__label t-small-text" :for="field.ref" v-html="field.name"></label>

                    <textarea
                        v-if="field.ref === 'message'"
                        :ref="`input_${ field.ref }`"
                        class="contact__input contact__input--textarea t-copy"
                        :id="field.ref"
                        :placeholder="field.placeholder"
                        @focus="onBlur">
                    </textarea>

                    <input
                        v-else
                        :ref="`input_${ field.ref }`"
                        class="contact__input t-copy"
                        :id="field.ref"
                        :placeholder="field.placeholder"
                        :type="field.ref === 'mail' ? 'email' : 'text'"
                        @focus="onBlur">

                    <div class="contact__field-error"></div>
                    <div class="contact__field-sucess"></div>
                </div>

                <button ref="button" class="t-copy t-black contact__button u-text-link" @click="onClick" v-html="`<span class='u-arrow-link'>→</span>&nbsp;&nbsp;${ content.form.send }`"></button>
            </form>
        </div>
    </section>
</template>

<script>

import { mapGetters } from 'vuex';
import axios from 'axios';
import gsap from 'gsap';
import InView from '../directives/InView';
import OnScroll from '../utils/OnScroll';
import SplitText from '../utils/SplitText';

export default {
    name: 'Contact',

    props: {
        content: Object,
        address: Array
    },


    data() {
        return {
            loading: false,
            sent: false,
            scrolls: []
        };
    },

    computed: {
        ...mapGetters(['isMobile']),

        addressHtml() {
            return this.address.join('<br>');
        }
    },

    watch: {
        isMobile() {
            this.reset();
        }
    },

    mounted() {
        this.prepareElements();
    },

    beforeDestroy() {
        this.scrolls.forEach((scroll) => {
            scroll.kill();
        });
    },

    methods: {
        reset() {
            if (this.isMobile) {
                this.scrolls.forEach((scroll) => {
                    scroll.kill();
                });

                this.scrolls = [];

                gsap.set(this.$refs.form, {
                    clearProps: 'all'
                });

                this.split && this.split.revert();

                if (this.$titleChars) {
                    gsap.to(this.$titleChars, {
                        duration: .7,
                        opacity: 1,
                        ease: 'power3.inOut',
                        stagger: {
                            each: 0.02,
                            ease: 'none'
                        }
                    });
                }
            } else {
                this.split && this.split.revert();
                this.prepareElements();

                if (this.$titleChars) {
                    gsap.to(this.$titleChars, {
                        duration: .7,
                        opacity: 1,
                        ease: 'power3.inOut',
                        stagger: {
                            each: 0.02,
                            ease: 'none'
                        }
                    });
                }
            }
        },

        prepareElements() {
            if (this.isMobile) return;

            this.split = new SplitText(this.$refs.title, { type: 'chars', charsClass: 'split' });
            this.$titleChars = this.split.chars;

            // title
            gsap.set(this.$titleChars, {
                opacity: 0,
            });

            gsap.set(this.$refs.form, {
                y: 100,
            });

            gsap.delayedCall(.5, () => {
                this.scrolls.push(new OnScroll(this.$refs.form, {
                    y: 0
                }, 'A'));
            });
        },

        animateIn() {
            if (this.isMobile) return;
            this.isInView = true;

            gsap.to(this.$titleChars, {
                duration: .7,
                opacity: 1,
                ease: 'power3.inOut',
                stagger: {
                    each: 0.02,
                    ease: 'none'
                }
            });
        },

        onClick(e) {
            e.stopPropagation();
            e.preventDefault();

            if (this.loading) {
                return;
            }

            this.loading = true;
            const payload = this.gatherData();

            axios({
                method: 'post',
                url: '/api/contact',
                data: payload
            })
            .then(this.handleRequest)
            .catch(this.handleRequestError);
        },

        gatherData() {
            const data = {
                name: this.$refs.input_name[0].value,
                surname: this.$refs.input_surname[0].value,
                email: this.$refs.input_mail[0].value,
                message: this.$refs.input_message[0].value,
            };

            return data;
        },

        handleRequest(response) {
            this.hasError = false;
            this.loading = false;
            this.sent = true;

            this.$refs.button.innerHTML = this.content.form.thanks;
        },

        handleRequestError(error) {
            if (error.response) {
                return this.onError(error, error.response);
            }
            return this.onError(error);
        },

        onError(error, response = false) {
            this.hasError = true;

            for (const key in response.data.errors) {
                switch (key) {
                case 'name':
                    this.content.form.inputs[0].hasError = true;
                    break;

                case 'surname':
                    this.content.form.inputs[1].hasError = true;
                    break;

                case 'email':
                    this.content.form.inputs[2].hasError = true;
                    break;

                case 'message':
                    this.content.form.inputs[3].hasError = true;
                    break;
                }
            }

            this.content.form.inputs.forEach((input) => {
                if (!input.hasError) {
                    input.hasSucess = true;
                }
            });

            this.loading = false;
        },

        onBlur() {
            if (this.hasError) {
                this.hasError = false;

                this.content.form.inputs.forEach((input) => {
                    input.hasError = false;
                    input.hasSucess = false;
                });

                this.$forceUpdate();
            }
        }
    }
};
</script>
