import md5 from 'md5';

export default function imageLoader(imageObject, options) {
    let optionsEncoded = '';

    if (options) {
        const isRetina = window.devicePixelRatio > 1;
        const optionKeys = Object.keys(options);

        if (isRetina) {
            optionKeys.forEach((key) => {
                if (['height', 'width'].includes(key)) options[key] *= 2;
            });
        }

        // modify options
        const optionsMd5 = md5(JSON.stringify(options));

        if (imageObject.conversions && imageObject.conversions[optionsMd5]) {
            // Conversion hit
            const conversion = imageObject.conversions[optionsMd5];
            return conversion.static_url;
        }

        optionsEncoded =
            optionKeys
            .map(value => `${ value }=${ encodeURIComponent(options[value]) }`)
            .join('&');
    }

    // Load via API endpoint
    return `/image/${ imageObject.id }/${ imageObject.name }.${ imageObject.extension }?${ optionsEncoded }`;
}
