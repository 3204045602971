<template>
    <div class="logo logo--development">
        <svg ref="logo" class="logo__logo" enable-background="new 0 0 843.04 330.65" viewBox="0 0 843.04 330.65" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <clipPath id="a">
                <path d="m0 0h240v240h-240z"/>
            </clipPath>
            <g clip-path="url(#a)" fill="none" stroke="#231f20" stroke-miterlimit="10" stroke-width="4.8" class="logo__logoLines" @click="onLogoClick">
                <path d="m-120-116.32 280 135.52 160.24-96.8"/>
                <path d="m-120-79.96 280 118.14 160.24-82.94"/>
                <path d="m-120-43.61 280 100.77 160.24-69.09"/>
                <path d="m-120-7.25 280 83.4 160.24-55.24"/>
                <path d="m-120 29.11 280 66.02 160.24-41.38"/>
                <path d="m-120 65.46 280 48.65 160.24-27.53"/>
                <path d="m-120 101.82 280 31.27 160.24-13.67"/>
                <path d="m-120 138.17 280 13.9 160.24.18"/>
                <path d="m-120 174.53 280-3.48 160.24 14.04"/>
                <path d="m-120 210.89 280-20.85 160.24 27.89"/>
                <path d="m-120 247.24 280-38.22 160.24 41.74"/>
                <path d="m-120 283.6 280-55.6 160.24 55.6"/>
            </g>
            <g fill="#030003" ref="logoText" class="logo__logoText">
                <path d="m300.65 179.12h34.85v11.7h-21.66v16.26h21.66v11.44h-21.66v20.82h-13.19z"/>
                <path d="m344.7 179.12h13.19v47.84h21.17v12.39h-34.36z"/>
                <path d="m382.72 209.24c0-17.12 11.32-31.84 30.45-31.84s30.45 14.71 30.45 31.84c0 17.12-11.32 31.84-30.45 31.84s-30.45-14.72-30.45-31.84zm47.14 0c0-10.5-5.86-19.36-16.69-19.36-10.91 0-16.77 8.86-16.69 19.36-.08 10.5 5.78 19.36 16.69 19.36 10.83 0 16.69-8.86 16.69-19.36z"/>
                <path d="m443.86 179.12h13.84l11.89 40.01 11.32-40.01h10.26l11.32 40.01 11.89-40.01h13.84l-20.11 60.23h-10.75l-11.32-37.86-11.32 37.86h-10.75z"/>
                <path d="m300.41 269.12h17.1c18.89 0 29.15 14.63 29.15 30.12 0 15.4-10.26 30.12-29.15 30.12h-17.1zm16.69 54.9c15.88 0 23.94-12.22 23.94-24.78s-8.06-24.87-23.94-24.87h-11.15v49.65z"/>
                <path d="m356.42 269.12h31.92v5.25h-26.38v22.37h26.38v5.33h-26.38v21.94h26.38v5.33h-31.92z"/>
                <path d="m394.28 269.12h5.94l16.45 51.28 16.53-51.28h5.94l-19.79 60.23h-5.29z"/>
                <path d="m446.22 269.12h31.92v5.25h-26.38v22.37h26.38v5.33h-26.38v21.94h26.38v5.33h-31.92z"/>
                <path d="m490.76 269.12h5.54v54.72h25.65v5.51h-31.18v-60.23z"/>
                <path d="m528.61 299.24c0-16.69 10.99-31.41 29.31-31.41s29.31 14.71 29.31 31.41c0 16.69-10.99 31.41-29.31 31.41s-29.31-14.72-29.31-31.41zm52.93 0c0-13.94-8.71-26.16-23.61-26.16s-23.61 12.22-23.53 26.16c-.08 13.85 8.63 26.07 23.53 26.07 14.89 0 23.61-12.22 23.61-26.07z"/>
                <path d="m636.08 289.6c0 11.96-7.9 20.56-18.81 20.56h-14.33v19.19h-5.54v-60.23h19.87c10.92 0 18.81 8.61 18.81 20.48zm-5.53 0c0-8.78-5.7-15.23-13.52-15.23h-14.09v30.46h14.09c7.82 0 13.52-6.45 13.52-15.23z"/>
                <path d="m644.39 269.12h5.37l20.84 52.14 20.93-52.14h5.29v60.23h-5.29v-48.01l-18.73 48.01h-4.4l-18.73-48.27v48.27h-5.29v-60.23z"/>
                <path d="m710.66 269.12h31.92v5.25h-26.38v22.37h26.38v5.33h-26.38v21.94h26.38v5.33h-31.92z"/>
                <path d="m755.19 269.12h5.94l28.74 50.59v-50.59h5.29v60.23h-5.94l-28.74-50.68v50.68h-5.29z"/>
                <path d="m820.08 274.63h-17.34v-5.51h40.3v5.51h-17.42v54.72h-5.54z"/>
            </g>
        </svg>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import gsap from 'gsap';
import EventBus from '../../EventBus';

export default {
    name: 'LogoDevelopment',

    mounted() {
        this.animateIn();
    },

    methods: {
        onLogoClick() {
            this.$router.push('/');
        },

        animateIn() {
            const delay = !this.logoIsIntro ? 0 : 2.5;

            if (this.$refs.logo) {
                gsap.set(this.$refs.logo, {
                    opacity: 0
                });

                gsap.to(this.$refs.logo, {
                    duration: 1,
                    opacity: 1,
                    delay,
                    ease: 'linear.none'
                });
            }
        }
    }
};
</script>
