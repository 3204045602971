import { render, staticRenderFns } from "./LocationBlock.vue?vue&type=template&id=415b9308"
import script from "./LocationBlock.vue?vue&type=script&lang=js"
export * from "./LocationBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports