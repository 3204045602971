<template>
    <div class="page home-page" v-if="content">
        <HomeHeader :content="content.main" v-if="!isMobile" />
        <HomeHeaderMobile :content="content.main" v-else />

        <div ref="sections" class="page__sections">
            <section ref="development" id="development" class="sticky-container">
                <div class="sticky-container__spacer"></div>
                <SectionHeader :content="content.development" type="columns"/>
                <div class="sticky-container__wrapper" :class="{ 'js-black-anchor': !isMobile && !isTablet }">
                    <FeaturedProject1 :content="content.development.featured[0]" />
                    <FeaturedProject2 :content="content.development.featured[1]" />
                    <FeaturedProject3 :content="content.development.featured[2]" />
                    <NewProjects :content="content.development" :slides="content.development.projects" :buttonText="button.more" />
                </div>
            </section>

            <section ref="investment" id="investment" class="sticky-container">
                <div class="sticky-container__spacer"></div>
                <SectionHeader class="section-header--investment" :content="content.investment"/>
                <div class="sticky-container__wrapper" :class="{ 'js-black-anchor': !isMobile && !isTablet }">
                    <VideoSection :content="content.investment.featured" />
                    <HomeSlider :slides="content.investment.projects"/>
                </div>
            </section>

            <SectionTeam ref="team" id="team" />
            <Contact ref="contact" id="contact" :content="content.contact" :address="general.address" />
            <Footer ref="footer" />
        </div>
    </div>
</template>

<script>

import gsap from 'gsap';
import { mapActions, mapGetters } from 'vuex';
import RouteMeta from '../utils/routeMeta.js';

import HomeHeader from '../blocks/HomeHeader.vue';
import HomeHeaderMobile from '../blocks/HomeHeaderMobile.vue';
import SectionHeader from '../blocks/SectionHeader.vue';
import SectionTeam from '../blocks/SectionTeam.vue';
import FeaturedProject1 from '../blocks/FeaturedProject1.vue';
import FeaturedProject2 from '../blocks/FeaturedProject2.vue';
import FeaturedProject3 from '../blocks/FeaturedProject3.vue';
import NewProjects from '../blocks/NewProjects.vue';
import VideoSection from '../blocks/VideoSection.vue';
import HomeSlider from '../blocks/HomeSlider.vue';
import Contact from '../blocks/Contact.vue';
import Footer from '../blocks/Footer.vue';

export default {
    name: 'Home',

    components: {
        SectionHeader,
        SectionTeam,
        FeaturedProject1,
        FeaturedProject2,
        FeaturedProject3,
        NewProjects,
        HomeHeader,
        HomeHeaderMobile,
        VideoSection,
        HomeSlider,
        Contact,
        Footer
    },

    computed: {
        ...mapGetters(['isMobile', 'isTablet', 'pages', 'general', 'isIntroFinished']),

        content() {
            return this.pages.home;
        },

        button() {
            return this.general.link_labels;
        }
    },

    mounted() {
        if (!this.pages.home) {
            this.getHome().then(this.ready);
        } else {
            this.ready();
        }
    },

    methods: {
        ...mapActions(['getHome']),

        ready() {
            this.setMetaTitle();

            this.$nextTick(() => {
                this.prepareElements();
                this.animateIn();
            });
        },

        setMetaTitle() {
            RouteMeta(this.$route.name, this.content.title);
        },

        prepareElements() {
            if (this.isMobile) return;
            this.elementsSetted = true;

            this.$headers = document.querySelectorAll('.section-header');
            this.$containers = document.querySelectorAll('.sticky-container__wrapper');

            this.$sections = [
                ...this.$containers,
                this.$refs.team.$el,
                this.$refs.contact.$el,
                this.$refs.footer.$el
            ];

            gsap.set([this.$sections, this.$headers], {
                opacity: 0
            });
        },

        animateIn() {
            if (this.isMobile && !this.elementsSetted) return;
            this.animatedIn = true;

            const delay = this.isIntroFinished ? .3 : 3;

            gsap.to(this.$sections, {
                duration: 1.5,
                opacity: 1,
                y: 0,
                delay,
                ease: 'power4.out'
            });

            gsap.to(this.$headers, {
                duration: 1.5,
                opacity: 1,
                y: 0,
                delay: delay + 1,
                ease: 'power4.out'
            });
        }
    }
};
</script>
