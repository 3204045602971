<template>
    <div class="project-header">
        <div class="project-header__container">
            <div class="project-header__copy">
                <div ref="header" class="project-header__title t-heading" v-html="titleCopy"></div>
                <a ref="link" :href="content.projectLink" target="_blank" class="project-header__link t-copy t-black" v-html="content.projectLinkName" ></a>
            </div>
            <div ref="visual" class="project-header__visual">
                <img ref="image" class="project-header__image" :src="headerImage" alt="project header image">
                <div ref="headerTwo" class="project-header__title-layer t-heading t-white" v-html="titleCopy"></div>
                <a ref="linkTwo" :href="content.projectLink" target="_blank" class="project-header__link-layer t-copy t-white" v-html="content.projectLinkName" ></a>
            </div>
        </div>
        <div ref="intro" class="project-header__intro" v-inview:enter="inViewFt">
            <div class="project-header__stats">
                <NumbersBlock :isInView="isInView" :content="content.numbers" type="column" />
            </div>
            <p class="project-header__text t-copy" v-html="content.intro"></p>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import gsap from 'gsap';
import OnScroll from '../utils/OnScroll';
import imageLoader from '../utils/imageLoader.js';
import NumbersBlock from '../components/NumbersBlock.vue';

export default {
    name: 'ProjectHeader',

    components: {
        NumbersBlock
    },

    props: {
        content: Object,
        startAnimation: Boolean
    },

    data() {
        return {
            isInView: false,
            scrolls: []
        };
    },

    computed: {
        ...mapGetters(['isMobile', 'isIntroFinished']),

        headerImage() {
            return imageLoader(this.content.headerImage[0], {
                width: 1120, // height: 676
            });
        },

        titleCopy() {
            return `${ this.content.title }&nbsp;—<br />${ this.content.city } `;
        }
    },

    watch: {
        isMobile() {
            this.reset();
        }
    },

    mounted() {
        this.prepareElements();
        this.animateIn();

        if (!this.isMobile) {
            this.parallaxElements();
        }
    },

    beforeDestroy() {
        this.scrolls.forEach((scroll) => {
            scroll.kill();
        });
    },

    methods: {
        reset() {
            if (this.isMobile) {
                this.scrolls.forEach((scroll) => {
                    scroll.kill();
                });

                this.scrolls = [];

                gsap.set([this.$refs.header, this.$refs.link, this.$refs.headerTwo, this.$refs.linkTwo], {
                    clearProps: 'all'
                });
            } else {
                this.prepareElements();
                this.animateIn();
            }
        },

        inViewFt() {
            this.isInView = true;
        },

        prepareElements() {
            if (this.isMobile) return;
            this.elementsSetted = true;

            gsap.set(this.$refs.image, {
                opacity: 0,
                y: 100
            });

            gsap.set(this.$refs.intro, {
                opacity: 0
            });

            gsap.set([this.$refs.header, this.$refs.link, this.$refs.headerTwo, this.$refs.linkTwo], {
                opacity: 0,
                y: 100
            });
        },

        animateIn() {
            if (this.isMobile && !this.elementsSetted) return;

            const delay = .1;

            gsap.to(this.$refs.image, {
                duration: 1.5,
                opacity: 1,
                y: 0,
                delay: delay + .1,
                ease: 'power4.out'
            });

            gsap.to([this.$refs.header, this.$refs.link, this.$refs.headerTwo, this.$refs.linkTwo], {
                duration: 1.5,
                opacity: 1,
                y: 0,
                delay,
                ease: 'power4.out'
            });

            gsap.to(this.$refs.intro, {
                duration: 1,
                delay: delay + .3,
                opacity: 1
            });
        },

        parallaxElements() {
            this.scrolls.push(new OnScroll(this.$refs.header, {
                y: -100
            }, 'C'));
            this.scrolls.push(new OnScroll(this.$refs.link, {
                y: -100
            }, 'C'));
            this.scrolls.push(new OnScroll(this.$refs.headerTwo, {
                y: -100
            }, 'C'));
            this.scrolls.push(new OnScroll(this.$refs.linkTwo, {
                y: -100
            }, 'C'));
        }
    }
};
</script>
