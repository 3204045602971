<template>
    <div class="page project-page" :class="{ 'project-page--development': isDevelopment, 'project-page--investment': isInvestment }">
        <ProjectHeader v-if="content && content.blocks" :content="content"/>
        <div ref="sections" class="page__sections">
            <div v-if="content && content.blocks">
                <component
                    :is="block.type"
                    v-for="(block, i) in content.blocks" :key="i + 1"
                    class="u-opacity-animate"
                    :class="`project-page__block project-page__block--${ block.type.toLowerCase() }`"
                    :content="{ ...general, ...block }"
                    :isMobile="isMobile"
                    :isRetina="isRetina"
                    v-inview="visible"
                />

                <div v-if="type === 'development'" class="project-page__next-button">
                    <router-link class="t-copy t-black u-text-link" :to="{ name: type, params: { slug: content.next } }" v-html="general.nextProject"></router-link>
                </div>
                <div v-if="type === 'investment'" class="project-page__next-button">
                    <router-link class="t-copy t-black u-text-link" :to="{ name: type, params: { slug: content.next } }" v-html="general.nextAsset"></router-link>
                </div>

                <AddressCopyBlock class="js-black-anchor" :content="addressCopy"/>
                <Footer/>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap';
import { mapActions, mapGetters } from 'vuex';
import RouteMeta from '../utils/routeMeta.js';

import VideoBlock from '../components/VideoBlock.vue';
import QuoteBlock from '../components/QuoteBlock.vue';
import SliderBlock from '../components/SliderBlock.vue';
import ReviewsBlock from '../components/ReviewsBlock.vue';
import LocationBlock from '../components/LocationBlock.vue';
import SpecsBlock from '../components/SpecsBlock.vue';
import TextColumnsBlock from '../components/TextColumnsBlock.vue';
import Footer from '../blocks/Footer.vue';
import ProjectHeader from '../blocks/ProjectHeader.vue';
import AddressCopyBlock from '../components/AddressCopyBlock.vue';

export default {
    name: 'Project',

    components: {
        VideoBlock,
        QuoteBlock,
        SliderBlock,
        ReviewsBlock,
        LocationBlock,
        SpecsBlock,
        TextColumnsBlock,
        ProjectHeader,
        AddressCopyBlock,
        Footer
    },

    props: {
        type: String,
        slug: String,
    },

    mounted() {
        const { slug, type } = this;
        if (!this.content) {
            this.getProject({ slug, type }).then(this.ready);
        } else {
            this.ready();
        }

        this.prepareElements();
        this.setIntroFinished(true);
    },

    data() {
        return {
            visible: 'visible',
            animatedIn: false
        };
    },

    computed: {
        ...mapGetters(['isMobile', 'isRetina', 'projects', 'general']),

        content() {
            return this.projects[this.type][this.slug];
        },

        addressCopy() {
            return { address: this.general.addressFooter, copy: this.general.address_copy };
        },

        isDevelopment() {
            return this.$route.name === 'development';
        },

        isInvestment() {
            return this.$route.name === 'investment';
        }
    },

    methods: {
        ...mapActions(['getProject', 'setIntroFinished']),

        ready() {
            this.setMetaTitle();

            this.$nextTick(() => {
                this.animateIn();
            });
        },

        setMetaTitle() {
            RouteMeta(this.$route.name, this.content.title);
        },

        prepareElements() {
            if (this.isMobile) return;
            this.elementsSetted = true;

            gsap.set(this.$refs.sections, {
                opacity: 0,
                y: 50
            });
        },

        animateIn() {
            if (this.isMobile && !this.elementsSetted) return;
            this.animatedIn = true;

            gsap.to(this.$refs.sections, {
                duration: 1.5,
                opacity: 1,
                y: 0,
                delay: .3,
                ease: 'power4.out'
            });
        }
    }
};
</script>
