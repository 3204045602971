<template>
    <div class="paragraph t-copy">
        <p class="paragraph__copy" v-html="description"></p>
        <router-link :to="content.slug" class="paragraph__button u-text-link" v-html="general.link_labels.more"></router-link>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
    name: 'FeatureParagraph',

    props: {
        content: Object,
        max: Number,
    },

    computed: {
        ...mapGetters(['general']),

        description() {
            const { max } = this;
            const { description } = this.content;
            let count = 0;
            const space = 1;

            if (description.length > max) {
                description.split(' ').forEach(word => {
                    // Add word length + 1 space for each word until max is reached
                    if (count <= this.max) count += word.length + space;
                });

                return `${ description.substring(0, count - space) }...`;
            }

            return description;
        }
    },
};

</script>

