import * as types from './mutation-types';

export const mutations = {

    //  ui
    [types.SET_IS_MOBILE](state, boolean) {
        state.isMobile = boolean;
    },

    [types.SET_IS_RETINA](state, boolean) {
        state.isRetina = boolean;
    },

    [types.SET_IS_INTRO_STARTED](state, boolean) {
        state.isIntroStarted = boolean;
    },

    [types.SET_IS_INTRO_FINISHED](state, boolean) {
        state.isIntroFinished = boolean;
    },

    [types.SET_WINDOW_WIDTH](state, boolean) {
        state.windowWidth = boolean;
    },

    [types.SET_LOGO_IS_INTRO](state, boolean) {
        state.logoIsIntro = boolean;
    },

    // data
    [types.ADD_PROJECT](state, { type, data }) {
        const newProject = { [data.slug]: data };
        state.projects[type] = { ...state.projects[type], ...newProject };
    },

    [types.ADD_HOME](state, data) {
        state.pages = data.pages;
    }
};
