<template>
    <div class="section-header js-black-section t-white" :class="{ 'js-black-anchor': isMobile || isTablet}">
        <div :class="`section-header--${ type }`" class="section-header__container">
            <h2 class="section-header__title t-heading" v-html="content.title"></h2>
            <div class="section-header__paragraph t-copy t-white">
                <p v-for="(item, i) in content.body" :key="i" class="section-header__text" v-html="item"></p>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'SectionHeader',

    props: {
        content: Object,
        type: {
            default: 'default',
            type: String
        },
    },

    computed: {
        ...mapGetters(['isMobile', 'isTablet'])
    },
};
</script>
