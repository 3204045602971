export const getters = {
    // ui
    isMobile: state => state.windowWidth <= window.Application.mobileBreakpoint,
    isTablet: state => state.windowWidth <= window.Application.tabletBreakpoint,
    isRetina: state => state.isRetina,
    isIntroStarted: state => state.isIntroStarted,
    isIntroFinished: state => state.isIntroFinished,
    logoIsIntro: state => state.logoIsIntro,

    // data
    general: state => state.general,
    pages: state => state.pages,
    projects: state => state.projects,

    // misc
    googleMapsKey: state => state.googleMapsKey
};
