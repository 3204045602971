<template>
    <div class="home-header">
        <div class="home-header__top">
            <img ref="building" class="home-header__building" src="/img/header/building.jpg" srcset="/img/header/building@2x.jpg 2x" alt="">

            <div class="home-header__top-right">
                <h1 ref="title" class="home-header__title t-home-title" v-html="content.title"></h1>
                <img ref="interior" class="home-header__interior" src="/img/header/interior.jpg" srcset="/img/header/interior@2x.jpg 2x" alt="">
            </div>
        </div>

        <div ref="room" class="home-header__bottom">
            <div class="home-header__bottom-top">
                <img ref="roomImg" class="home-header__bottom-img" src="/img/header/room.jpg" srcset="/img/header/room@2x.jpg 2x" alt="">
                <p class="home-header__bottom-copy t-copy" v-html="content.bodyOne"></p>
            </div>
            <p class="home-header__bottom-bottom t-copy" v-html="content.bodyTwo"></p>
        </div>
    </div>
</template>

<script>

import gsap from 'gsap';
import { mapGetters } from 'vuex';
import OnScroll from '../utils/OnScroll';

export default {
    name: 'HomeHeader',

    props: {
        content: Object,
    },

    computed: {
        ...mapGetters(['isIntroFinished'])
    },

    mounted() {
        this.prepareElements();
        this.animateIn();
        this.parallaxElements();
    },

    beforeDestroy() {
        this.buildingScroll && this.buildingScroll.kill();
        this.titleScroll && this.titleScroll.kill();
        this.interiorScroll && this.interiorScroll.kill();
        this.roomImgScroll && this.roomImgScroll.kill();
        this.roomScroll && this.roomScroll.kill();
    },

    methods: {
        prepareElements() {
            gsap.set(this.$refs.roomImg, {
                opacity: 0,
                scale: 0.8,
                transformOrigin: 'center'
            });
        },

        parallaxElements() {
            this.buildingScroll = new OnScroll(this.$refs.building, {
                y: -100
            }, 'C');

            this.titleScroll = new OnScroll(this.$refs.title, {
                y: 40
            }, 'C');

            this.interiorScroll = new OnScroll(this.$refs.interior, {
                y: 0
            }, 'C');

            this.roomScroll = new OnScroll(this.$refs.room, {
                y: -200
            }, 'C');

            this.elScroll = new OnScroll(this.$el, {
                paddingBottom: 100
            }, 'C');

            this.roomImgScroll = new OnScroll(this.$refs.roomImg, {
                opacity: 1,
                scale: 1,
            }, 'A', true);
        },

        animateIn() {
            const delay = this.isIntroFinished ? .1 : 2.4;

            gsap.set([this.$refs.building, this.$refs.title, this.$refs.interior], {
                autoAlpha: 0,
                y: 150
            });

            gsap.to([this.$refs.building, this.$refs.title, this.$refs.interior], {
                duration: .85,
                y: 0,
                stagger: .08,
                delay,
                ease: 'power3.out'
            });

            gsap.to([this.$refs.building, this.$refs.title, this.$refs.interior], {
                duration: .95,
                autoAlpha: 1,
                stagger: .08,
                delay,
                ease: 'linear.easeNone'
            });
        }
    }
};
</script>
