<template>
    <div class="footer t-white">
        <div class="footer__container t-footer">
            <div class="footer__top">
                <ul class="footer__list">
                    <li class="footer__item t-footer t-link-opacity" v-for="(item, i) in content.items" :key="i">
                        <router-link :to="{ name: 'home', hash: item.target }" v-html="item.copy"></router-link>
                    </li>
                </ul>
                <div class="footer__right">
                    <button @click="onClick" class="footer__back u-text-top t-credit t-link-opacity" v-html="content.back"></button>
                    <div class="footer__socialMobile">
                        <a class="footer__socialItem" href="https://www.linkedin.com/company/flowrealestate/" target="_blank">
                            <svg version="1.1" viewBox="0 0 25 25" width="25" height="25" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <defs>
                                    <polygon id="a" points="1.186 1.3605 24.878 1.3605 24.878 25.053 1.186 25.053"/>
                                </defs>
                                <g fill="none" fill-rule="evenodd">
                                    <g transform="translate(-130 -279)">
                                        <g transform="translate(78 278)">
                                            <g transform="translate(51.737 .11303)">
                                            <mask id="b" fill="white">
                                                <use xlink:href="#a"/>
                                            </mask>
                                            <path d="m20.929 20.116h-2.9606v-5.5322c0-3.3246-3.9495-3.0726-3.9495 0v5.5322h-2.9606v-10.858h2.9606v1.7419c1.3794-2.5524 6.9101-2.7411 6.9101 2.4448v6.6714zm-13.327-12.111c-0.95347 0-1.7272-0.77811-1.7272-1.7404s0.77368-1.7419 1.7272-1.7419c0.95347 0 1.7272 0.77958 1.7272 1.7419s-0.77221 1.7404-1.7272 1.7404zm-1.4811 12.111h2.9621v-10.858h-2.9621v10.858zm13.822-18.756h-13.822c-2.7248 0-4.9354 2.2091-4.9354 4.9354v13.82c0 2.7278 2.2105 4.9368 4.9354 4.9368h13.822c2.7248 0 4.9354-2.2091 4.9354-4.9368v-13.82c0-2.7263-2.2105-4.9354-4.9354-4.9354z" fill="#fff" mask="url(#b)"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <div class="footer__bottom">
                <div class="footer__social">
                    <a class="footer__socialItem t-link-opacity" href="https://www.linkedin.com/company/flowrealestate/" target="_blank">
                        <svg version="1.1" viewBox="0 0 25 25" width="25" height="25" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <defs>
                                <polygon id="a" points="1.186 1.3605 24.878 1.3605 24.878 25.053 1.186 25.053"/>
                            </defs>
                            <g fill="none" fill-rule="evenodd">
                                <g transform="translate(-130 -279)">
                                    <g transform="translate(78 278)">
                                        <g transform="translate(51.737 .11303)">
                                        <mask id="b" fill="white">
                                            <use xlink:href="#a"/>
                                        </mask>
                                        <path d="m20.929 20.116h-2.9606v-5.5322c0-3.3246-3.9495-3.0726-3.9495 0v5.5322h-2.9606v-10.858h2.9606v1.7419c1.3794-2.5524 6.9101-2.7411 6.9101 2.4448v6.6714zm-13.327-12.111c-0.95347 0-1.7272-0.77811-1.7272-1.7404s0.77368-1.7419 1.7272-1.7419c0.95347 0 1.7272 0.77958 1.7272 1.7419s-0.77221 1.7404-1.7272 1.7404zm-1.4811 12.111h2.9621v-10.858h-2.9621v10.858zm13.822-18.756h-13.822c-2.7248 0-4.9354 2.2091-4.9354 4.9354v13.82c0 2.7278 2.2105 4.9368 4.9354 4.9368h13.822c2.7248 0 4.9354-2.2091 4.9354-4.9368v-13.82c0-2.7263-2.2105-4.9354-4.9354-4.9354z" fill="#fff" mask="url(#b)"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>

                <div class="footer__credits t-credit t-gray">
                    <div class="footer__sign ">© {{ new Date().getFullYear() }} <span v-html="content.rights"></span></div>
                    <span v-if="isMobile">|</span>
                    <a target="_blank" href="/privacy_policy.pdf" class="footer__disclaimer  t-link-gray" v-html="content.disclaimer"></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import scrollTo from '../utils/scrollTo.js';
import EventBus from '../EventBus';

export default {
    name: 'Footer',

    components: {
    },

    computed: {
        ...mapGetters(['isMobile', 'isTablet', 'general']),

        content() {
            const { footer, rights, disclaimer } = this.general;

            return {
                ...footer,
                disclaimer,
                rights,
            };
        }
    },

    mounted() {
    },

    methods: {
        onClick(target) {
            scrollTo('#wrapper');
        }
    }
};
</script>
