import * as types from './mutation-types';
import axios from 'axios';

// import mocks from 'app/store/mocks';

// actions
export const actions = {
    setMobile(context, boolean) {
        context.commit(types.SET_IS_MOBILE, boolean);
    },

    setRetina(context, boolean) {
        context.commit(types.SET_IS_RETINA, boolean);
    },

    setWindowWidth(context, width) {
        context.commit(types.SET_WINDOW_WIDTH, width);
    },

    setIntroStarted(context, boolean) {
        context.commit(types.SET_IS_INTRO_STARTED, boolean);
    },

    setIntroFinished(context, boolean) {
        context.commit(types.SET_IS_INTRO_FINISHED, boolean);
    },

    setLogoIsIntro(context, boolean) {
        context.commit(types.SET_LOGO_IS_INTRO, boolean);
    },

    getProject(context, { slug, type }) {
        return new Promise((resolve, reject) => {
            const endpoint = `/api/${ type }/${ slug }`;
            axios.get(endpoint)
            .then(({ data }) => {
                context.commit(types.ADD_PROJECT, { type, data });
            })
            .then(resolve)
            .catch(reject);
        });
    },

    getHome(context) {
        return new Promise((resolve, reject) => {
            axios.get('/api/home')
            .then(({ data }) => {
                context.commit(types.ADD_HOME, data);
            })
            .then(resolve)
            .catch(reject);
        });
    }
};
