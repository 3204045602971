<template>
    <div class="cookie">
        <p class="cookie__copy t-cookie-small" v-html="content.copy"></p>
        <button class="cookie__btn t-cookie" @click="onClick" v-html="content.close"></button>
    </div>
</template>

<script>

import Cookies from 'js-cookie';
import { mapGetters } from 'vuex';
import gsap from 'gsap';

export default {
    name: 'Cookie',

    props: {
        content: Object
    },

    computed: {
        ...mapGetters(['isIntroFinished'])
    },

    mounted() {
        gsap.set(this.$el, {
            yPercent: 100
        });

        this.animateIn();
    },

    methods: {
        onClick() {
            Cookies.set('policy', true, { expires: 365 });

            gsap.to(this.$el, {
                yPercent: 100,
                ease: 'power3.out'
            });
        },

        animateIn() {
            const delay = this.isIntroFinished ? 1 : 3.2;

            gsap.to(this.$el, {
                yPercent: 0,
                delay,
                ease: 'power3.out'
            });
        }
    }
};
</script>
