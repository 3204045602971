<template>
    <div class="video-block">
        <VimeoVideo class="video-block__video" :loop="false" :autoPlay="false" :url="content.video"/>
    </div>
</template>

<script>

import VimeoVideo from './VimeoVideo.vue';

export default {
    name: 'VideoBlock',

    components: {
        VimeoVideo
    },

    props: {
        content: Object
    }
};
</script>
