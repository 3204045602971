<template>
    <div class="reviews-block">
        <h2 class="t-heading reviews-block__heading" v-html="content.inTheMedia"></h2>

        <blockquote class="reviews-block__blockquote" v-for="(review, i) in content.reviews" :key="i">
            <a class="t-link-black" :href="review.link" v-if="review.link" rel="noopener" target="_blank">
                <cite class="reviews-block__cite t-small-text" v-html="citeText(review)"></cite>
                <p class="reviews-block__quote t-copy">“<span v-html="review.text"></span>”</p>
            </a>
            <template v-else>
                <cite class="reviews-block__cite t-small-text" v-html="citeText(review)"></cite>
                <p class="reviews-block__quote t-copy">“<span v-html="review.text"></span>”</p>
            </template>
        </blockquote>

    </div>
</template>

<script>
export default {
    name: 'ReviewBlock',

    props: {
        content: Object,
    },

    methods: {
        citeText(review) {
            let citeText = review.source;
            if (review.date) citeText = `${ citeText }, ${ review.date }`;

            return citeText;
        }
    }
}
</script>
