<template>
    <section class="slider-block">
        <div class="slider-block__container">
            <ul ref="list" class="slider-block__list">
                <li ref="items" class="slider-block__item" :class="{ 'slider-block__item--nav': previousId === key || nextId === key }" v-for="(item, key) in slides" :key="key" @click="onClick(key)">

                    <div class="slider-block__item-container">
                        <div class="slider-block__image" :style="{ backgroundImage: `url('${ imageLoader(item, {
                            width: 2048
                        }) }')` }"></div>
                    </div>

                    <transition name="fade-arrows">
                        <div class="slider-block__arrow" v-if="nextId === key"></div>
                    </transition>
                    <transition name="fade-arrows">
                        <div class="slider-block__arrow slider-block__arrow--right" v-if="previousId === key"></div>
                    </transition>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import gsap from 'gsap';
import { mapActions, mapGetters } from 'vuex';
import imageLoader from '../utils/imageLoader.js';
import SimpleSwipeController from '../utils/SimpleSwipeController';
import EventBus from '../EventBus';

export default {
    name: 'SliderBlock',

    props: {
        content: Object
    },

    computed: {
        ...mapGetters(['isMobile']),

        slides() {
            return this.content.images;
        },

        previousId() {
            return this.isMobile ? -1 : this.selected - 1;
        },

        nextId() {
            return this.selected + 1;
        },

        offset() {
            return this.isMobile ? 12 : 20;
        }
    },

    data() {
        return {
            selected: 1,
            imageLoader
        };
    },

    mounted() {
        this.slide(-1);

        if (this.isMobile) {
            this.navSwipeController = new SimpleSwipeController({ $el: this.$refs.list, trigger: this.onSwipe });
            this.navSwipeController.initialize();
        }

        EventBus.$on('app:resize', this.resize);
    },

    beforeDestroy() {
        EventBus.$off('app:resize', this.resize);
        this.navSwipeController && this.navSwipeController.terminate();
    },

    methods: {

        onSwipe(dir) {
            if (this.sliding) return;

            if (dir === -1) {
                if (this.selected === this.slides.length - 1) return;
                this.selected += 1;
                this.slide(-1);
            } else {
                if (this.selected === 0) return;
                this.selected -= 1;
                this.slide(1);
            }
        },

        onClick(key) {
            if (this.sliding) return;

            if (key === this.nextId) {
                this.selected += 1;
                this.slide(-1);
            } else if (key === this.previousId) {
                this.selected -= 1;
                this.slide(1);
            }
        },

        slide(direction) {
            this.sliding = true;

            const width = this.offset + this.$refs.items[0].offsetWidth;
            const x = direction === 1 ? width : -width;

            gsap.to(this.$refs.list, {
                duration: 1.25,
                x: `+=${ x }`,
                ease: 'power4.out',
                onComplete: () => {
                    this.sliding = false;
                }
            });
        },

        resize() {
            const x = -(this.offset + this.$refs.items[0].offsetWidth) * this.selected;
            gsap.set(this.$refs.list, { x });
        }
    }
};
</script>
