<template>
    <div class="location-block">
        <div class="location-block__container">
            <div class="location-block__copy">
                <p class="t-small-text" v-html="general.location"></p>
                <div
                    class="location-block__address t-copy"
                    v-html="addressCopy"
                ></div>
            </div>
            <div class="location-block__map" id="map">
                <GoogleMapLoader
                    :mapConfig="mapConfig"
                    :apiKey="googleMapsKey"
                    :zoomAddress="`${this.content.address} ${this.content.city}`"
                    :addMarker="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GoogleMapLoader from './GoogleMapLoader.vue';

export default {
    name: 'LocationBlock',

    components: {
        GoogleMapLoader
    },

    props: {
        content: Object
    },

    computed: {
        ...mapGetters(['general', 'googleMapsKey']),

        mapConfig() {
            return {
                disableDefaultUI: true,
                zoom: 14,
                center: {
                    lat: 52.379189,
                    lng: 4.899431
                },
                styles: [
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{ color: '#e9e9e9' }, { lightness: 17 }]
                    },
                    {
                        featureType: 'landscape',
                        elementType: 'geometry',
                        stylers: [{ color: '#f5f5f5' }, { lightness: 20 }]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.fill',
                        stylers: [{ color: '#ffffff' }, { lightness: 17 }]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [
                            { color: '#ffffff' },
                            { lightness: 29 },
                            { weight: 0.2 }
                        ]
                    },
                    {
                        featureType: 'road.arterial',
                        elementType: 'geometry',
                        stylers: [{ color: '#ffffff' }, { lightness: 18 }]
                    },
                    {
                        featureType: 'road.local',
                        elementType: 'geometry',
                        stylers: [{ color: '#ffffff' }, { lightness: 16 }]
                    },
                    {
                        featureType: 'poi',
                        elementType: 'geometry',
                        stylers: [{ color: '#f5f5f5' }, { lightness: 21 }]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [{ color: '#dedede' }, { lightness: 21 }]
                    },
                    {
                        elementType: 'labels.text.stroke',
                        stylers: [
                            { visibility: 'on' },
                            { color: '#ffffff' },
                            { lightness: 16 }
                        ]
                    },
                    {
                        elementType: 'labels.text.fill',
                        stylers: [
                            { saturation: 36 },
                            { color: '#333333' },
                            { lightness: 40 }
                        ]
                    },
                    {
                        elementType: 'labels.icon',
                        stylers: [{ visibility: 'off' }]
                    },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{ color: '#f2f2f2' }, { lightness: 19 }]
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'geometry.fill',
                        stylers: [{ color: '#fefefe' }, { lightness: 20 }]
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'geometry.stroke',
                        stylers: [
                            { color: '#fefefe' },
                            { lightness: 17 },
                            { weight: 1.2 }
                        ]
                    }
                ]
            };
        },

        addressCopy() {
            return `${ this.content.address }<br />${ this.content.postalcode }&nbsp;${ this.content.city } `;
        }
    }
};
</script>
