// import IntersectionObserverPolyfill from 'intersection-observer';

// Thresold: indicates at which percentage of the target's visibility the observer's this.callback should be executed
// TriggerOnce: will trigger enter only once when visible.
// BackAndForth: will trigger enter and leave everytime an element changes visibility.
// againIfScrolledBack: will trigger enter when visible, and trigger leave if scrolling back up hiding the element. (like edge.tech)
// Use: v-inview:enter="enter", v-inview:leave="leave", v-inview="className", v-inview

class InView {
    constructor() {
        this.options = {
            threshold: 0.3,
            defaultClass: 'visible',
            behaviour: {
                backAndForth: false,
                triggerOnce: true,
                againIfScrolledBack: false
            }
        };

        this.init = this.init.bind(this);
    }

    init(el, binding, vm) {
        this.el = el;

        switch (typeof binding.value) {
        case 'function':
            this.callback = binding.value;
            break;

        case 'object':
            this.callback = binding.value.cb;
            this.options.threshold = binding.value.threshold || this.options.threshold;
            break;
        default:
            break;
        }


        this.isEnterDirective = binding.arg === 'enter';
        this.isLeaveDirective = binding.arg === 'leave';

        this.isClassDirective = !binding.arg;
        this.className = binding.expression || this.options.defaultClass;
        this.visible = false;
        this.vm = vm;

        if (this.el.offsetHeight > window.innerHeight) {
            this.rootMargin = `${ (this.el.offsetHeight * this.options.threshold) - (window.innerHeight * this.options.threshold) - 1 }px`;
        } else {
            this.rootMargin = '-1px';
        }

        if (this.options.behaviour.triggerOnce) {
            this.triggerOnce();
        } else if (this.options.behaviour.backAndForth) {
            this.backAndForth();
        } else if (this.options.behaviour.againIfScrolledBack) {
            this.againIfScrolledBack();
        }
    }

    triggerOnce() {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                this.visible = !this.visible;

                if (this.visible && this.isEnterDirective) {
                    this.callback();
                    observer.unobserve(this.el);
                }
                if (this.visible && this.isClassDirective) {
                    this.el.classList.add(this.className);
                    observer.unobserve(this.el);
                }
            }
        }, {
            threshold: this.options.threshold,
            rootMargin: this.rootMargin
        });
        observer.observe(this.el);
    }

    backAndForth() {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                this.visible = !this.visible;

                if ((this.visible && this.isEnterDirective) || (!this.visible && this.isLeaveDirective)) {
                    this.callback(this.vm);
                }

                if (this.visible && this.isClassDirective) {
                    this.el.classList.add(this.className);
                }

                if (!this.visible && this.isClassDirective) {
                    this.el.classList.remove(this.className);
                }
            }
        }, {
            threshold: this.options.threshold,
            rootMargin: this.rootMargin
        });
        observer.observe(this.el);
    }

    againIfScrolledBack() {
        const observer = new IntersectionObserver((entries) => {
            const entering = !this.visible && entries[0].intersectionRatio >= this.options.threshold;
            const leaving = this.visible && entries[0].intersectionRatio === 0 && entries[0].boundingClientRect.top > 0;

            if (entering || leaving) {
                this.visible = !this.visible;
            }

            if ((entering && this.isEnterDirective) || (leaving && this.isLeaveDirective)) {
                this.callback();
            }

            if (entering && this.isClassDirective) {
                this.el.classList.add(this.className);
            }

            if (leaving && this.isClassDirective) {
                this.el.classList.remove(this.className);
            }
        }, {
            threshold: [0, this.options.threshold],
            rootMargin: this.rootMargin
        });
        observer.observe(this.el);
    }
}

const InViewPublic = {
    install(Vue) {
        Vue.directive('inview', {
            inserted: (el, binding, vm) => {
                const inview = new InView();
                inview.init(el, binding, vm);
            }
        });
    }
};

export default InViewPublic;
