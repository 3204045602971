<template>
    <section class="section-team js-black-section js-black-anchor" v-inview:enter="animateIn">
        <div class="section-team__container">
            <div ref="img" v-if="!isMobile" class="section-team__left">
                <img ref="imageMember" v-for="(member, i) in content.members" :key="i" class="section-team__image" :src="member.image" :srcset="member.retina" :alt="member.name">
            </div>

            <div ref="copy" class="section-team__copy t-copy t-white">
                <h2 ref="title" class="section-team__heading t-heading" v-html="content.heading"></h2>
                <p ref="paragraph" class="section-team__intro" v-html="content.intro"></p>

                <div v-if="isMobile" class="section-team__left" >
                    <img ref="imageMember" v-for="(member, i) in content.members" :key="i" class="section-team__image" :src="member.image" :srcset="member.retina" :alt="member.name">
                </div>

                <div ref="contact" class="section-team__contact">
                    <div ref="infoMember" v-for="(member, i) in content.members" :key="i" class="section-team__info" :class="{ active: i === currentIndex }">
                        <div v-html="member.name"></div>
                        <div class="section-team__social">
                            <a v-if="member.linkedin" class="footer__socialItem" :href="member.linkedin" target="_blank">
                                <svg version="1.1" viewBox="0 0 25 25" width="35" height="35" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <defs>
                                        <polygon id="a" points="1.186 1.3605 24.878 1.3605 24.878 25.053 1.186 25.053"/>
                                    </defs>
                                    <g fill="none" fill-rule="evenodd">
                                        <g transform="translate(-130 -279)">
                                            <g transform="translate(78 278)">
                                                <g transform="translate(51.737 .11303)">
                                                <mask id="b" fill="white">
                                                    <use xlink:href="#a"/>
                                                </mask>
                                                <path d="m20.929 20.116h-2.9606v-5.5322c0-3.3246-3.9495-3.0726-3.9495 0v5.5322h-2.9606v-10.858h2.9606v1.7419c1.3794-2.5524 6.9101-2.7411 6.9101 2.4448v6.6714zm-13.327-12.111c-0.95347 0-1.7272-0.77811-1.7272-1.7404s0.77368-1.7419 1.7272-1.7419c0.95347 0 1.7272 0.77958 1.7272 1.7419s-0.77221 1.7404-1.7272 1.7404zm-1.4811 12.111h2.9621v-10.858h-2.9621v10.858zm13.822-18.756h-13.822c-2.7248 0-4.9354 2.2091-4.9354 4.9354v13.82c0 2.7278 2.2105 4.9368 4.9354 4.9368h13.822c2.7248 0 4.9354-2.2091 4.9354-4.9368v-13.82c0-2.7263-2.2105-4.9354-4.9354-4.9354z" fill="#0065F2" mask="url(#b)"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <button ref="arrow" @click="onClick(1)" class="section-team__arrow section-team__prev t-arrow">←</button>
            <button ref="arrowRight" @click="onClick(0)" class="section-team__arrow section-team__next t-arrow">→</button>

        </div>
    </section>
</template>

<script>

import { mapGetters } from 'vuex';
import gsap from 'gsap';
import InView from '../directives/InView';
import OnScroll from '../utils/OnScroll';
import SplitText from '../utils/SplitText';

export default {
    name: 'SectionTeam',

    computed: {
        ...mapGetters(['isMobile', 'general']),

        content() {
            return this.general.team;
        },

        membersAmount() {
            return this.content.members.length;
        },
    },

    data() {
        return {
            currentIndex: 0,
            isAnimating: false,
            scrolls: []
        };
    },

    watch: {
        isMobile() {
            this.initSlider();

            if (this.isMobile) {
                this.scrolls.forEach((scroll) => {
                    scroll.kill();
                });

                this.scrolls = [];

                gsap.set([this.$refs.arrow, this.$refs.arrowRight, this.$refs.paragraph, this.$refs.contact, this.$refs.img, this.$refs.copy], {
                    clearProps: 'all'
                });

                this.split && this.split.revert();

                if (this.$titleChars) {
                    gsap.to(this.$titleChars, {
                        duration: .7,
                        opacity: 1,
                        ease: 'power3.inOut',
                        stagger: {
                            each: 0.02,
                            ease: 'none'
                        }
                    });
                }
            } else {
                this.split && this.split.revert();
                this.prepareElements();
                this.animateIn();
            }
        }
    },

    mounted() {
        this.initSlider();
        this.prepareElements();
    },

    beforeDestroy() {
        this.scrolls.forEach((scroll) => {
            scroll.kill();
        });
    },

    methods: {
        prepareElements() {
            if (this.isMobile) return;

            this.split = new SplitText(this.$refs.title, { type: 'chars', charsClass: 'split' });
            this.$titleChars = this.split.chars;


            // title
            gsap.set(this.$titleChars, {
                opacity: 0,
            });

            gsap.set([this.$refs.arrow, this.$refs.arrowRight, this.$refs.paragraph, this.$refs.contact], {
                opacity: 0,
            });

            // Photos
            gsap.set(this.$refs.img, {
                opacity: 0,
            });

            gsap.set(this.$refs.copy, {
                y: 100,
            });

            gsap.delayedCall(.5, () => {
                this.scrolls.push(new OnScroll(this.$refs.copy, {
                    y: 0
                }, 'C'));
            });
        },

        animateIn() {
            if (this.isMobile) return;
            this.isInView = true;

            gsap.to(this.$refs.img, {
                duration: 1,
                opacity: 1
            });

            gsap.to(this.$titleChars, {
                duration: 1,
                opacity: 1,
                ease: 'power3.inOut',
                stagger: {
                    each: 0.035,
                    ease: 'none'
                }
            });

            gsap.to([this.$refs.arrow, this.$refs.arrowRight, this.$refs.paragraph, this.$refs.contact], {
                duration: .8,
                opacity: 1,
                delay: .65,
                ease: 'none'
            });
        },

        initSlider() {
            this.$nextTick(() => {
                gsap.set([this.$refs.imageMember[this.currentIndex], this.$refs.infoMember[this.currentIndex]], {
                    opacity: 1,
                });
            });
        },

        onClick(direction) {
            if (!this.isAnimating) {
                this.isAnimating = true;
                this.lastIndex = this.currentIndex;
                const index = direction <= 0 ? this.getNextIndex() : this.getPrevIndex();
                this.slide(index);
            }
        },

        slide(index) {
            const dir = this.currentIndex > index ? -1 : 1;
            this.currentIndex = index;

            // Image member Anims
            gsap.to(this.$refs.imageMember[this.lastIndex], {
                duration: .5,
                opacity: 0
            });
            gsap.to(this.$refs.imageMember[this.lastIndex], {
                duration: .7,
                x: dir === 1 ? 20 : -20,
                ease: 'power1.out'
            });

            gsap.fromTo(this.$refs.imageMember[this.currentIndex], {
                opacity: 0,
            }, {
                duration: .7,
                opacity: 1,
                delay: .6
            });
            gsap.fromTo(this.$refs.imageMember[this.currentIndex], {
                x: dir === 1 ? -20 : 20
            }, {
                duration: .7,
                x: 0,
                delay: .5,
                ease: 'power2.out'
            });


            // Contact member info Anims
            gsap.to(this.$refs.infoMember[this.lastIndex], {
                duration: .5,
                opacity: 0,
            });

            gsap.to(this.$refs.infoMember[this.lastIndex], {
                duration: .7,
                x: dir === 1 ? 20 : -20,
                ease: 'power1.out',
            });

            gsap.fromTo(this.$refs.infoMember[this.currentIndex], {
                opacity: 0,
            }, {
                duration: .7,
                opacity: 1,
                delay: .6
            });

            gsap.fromTo(this.$refs.infoMember[this.currentIndex], {
                x: dir === 1 ? -20 : 20
            }, {
                duration: .7,
                x: 0,
                delay: .5,
                ease: 'power2.out',
                onComplete: () => {
                    this.isAnimating = false;
                }
            });
        },

        getNextIndex() {
            let index;
            if (this.currentIndex >= this.membersAmount - 1) {
                index = 0;
            } else if (this.currentIndex < 0) {
                index = this.membersAmount - 1;
            } else {
                index = this.currentIndex + 1;
            }
            return index;
        },
        getPrevIndex() {
            let index;
            if (this.currentIndex <= 0) {
                index = this.membersAmount - 1;
            } else {
                index = this.currentIndex - 1;
            }
            return index;
        },
    }
};
</script>
