<template>
    <div class="numbers-block" :class="`numbers-block--${ type }`">
        <div class="numbers-block__item" v-if="content.stat_1.title">
            <div class="numbers-block__label t-copy" v-html="content.stat_1.title"></div>
            <div class="numbers-block__text t-number">
                <div class="numbers-block__copy" v-html="numberOne"></div>
                <div class="numbers-block__copy numbers-block__copy--m" v-html="formatExtension(content.stat_1.value.unit)"></div>
            </div>
        </div>
        <div class="numbers-block__item" v-if="content.stat_2.title">
            <div class="numbers-block__label t-copy" v-html="content.stat_2.title"></div>
            <div class="numbers-block__text t-number">
                <div class="numbers-block__copy" v-html="numberTwo"></div>
                <div class="numbers-block__copy numbers-block__copy--m" v-html="formatExtension(content.stat_2.value.unit)"></div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import gsap from 'gsap';

export default {
    name: 'NumbersBlock',

    props: {
        content: Object,
        type: {
            type: String,
            default: 'default'
        },
        isInView: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            numberOne: 0,
            numberTwo: 0
        };
    },

    computed: {
        ...mapGetters(['isMobile'])
    },

    watch: {
        isInView() {
            this.animateIn();
        },

    },

    mounted() {
        this.numberOne = this.content.stat_1.value.number;
        this.numberTwo = this.content.stat_2.value.number;
    },

    methods: {
        formatExtension(copy) {
            if (!copy.includes('m2')) {
                return `&nbsp;${ copy }`;
            }

            return copy.split('m2').join('m<sup>2</sup>');
        },

        numberWithCommas(value, copy) {
            if (!copy.includes('m2')) {
                return value;
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&#44;');
        },

        animateIn() {
            this.inView = true;

            if (this.content.stat_1.value.number) {
                // first
                const nb = this.content.stat_1.value.number;

                const cont = { val: nb >= 80 ? nb - 80 : nb - (nb / 2) };
                const NewVal = nb;

                gsap.to(cont, {
                    duration: 1.5,
                    val: NewVal,
                    roundProps: 'val',
                    ease: 'power4.out',
                    onUpdate: () => {
                        this.numberOne = cont.val > 1000 ?
                            this.numberWithCommas(cont.val, this.content.stat_1.value.unit) :
                            cont.val;
                    }
                });
            }

            if (this.content.stat_2.value.number) {
                // Second
                const nb2 = this.content.stat_2.value.number;

                const cont2 = { val: nb2 >= 80 ? nb2 - 80 : nb2 - (nb2 / 2) };
                const NewVal2 = nb2;

                gsap.to(cont2, {
                    duration: 1.5,
                    val: NewVal2,
                    roundProps: 'val',
                    delay: .3,
                    ease: 'power4.out',
                    onUpdate: () => {
                        this.numberTwo = cont2.val > 1000 ?
                            this.numberWithCommas(cont2.val, this.content.stat_2.value.unit) :
                            cont2.val;
                    }
                });
            }
        }
    }
};
</script>
