<template>
    <div class="home-header">
        <h1 class="home-header__title t-home-title" v-html="content.title"></h1>
        <img class="home-header__img home-header__building" src="/img/header/building.jpg" srcset="/img/header/building@2x.jpg 2x" alt="">
        <p class="home-header__copy t-copy" v-html="content.bodyOne"></p>
        <img class="home-header__img home-header__interior" src="/img/header/room.jpg" srcset="/img/header/room@2x.jpg 2x" alt="">
        <p class="home-header__copy t-copy" v-html="content.bodyTwo"></p>
    </div>
</template>

<script>

export default {
    name: 'HomeHeader',

    props: {
        content: Object
    }
};
</script>
