import gsap from 'gsap';

export default function scrollTo(id, ctx = {}) {
    return new Promise((resolve, reject) => {
        const $target = document.querySelector(id);
        if (!$target) return;

        const target = $target.getBoundingClientRect().top + window.Application.scroll;
        const scrollAmount = { y: window.Application.scroll };

        const onUpdate = () => window.scrollTo(0, scrollAmount.y);

        const killTweens = () => {
            ctx.scrollingTo = false;
            gsap.killTweensOf(scrollAmount);
        };

        window.addEventListener('wheel', killTweens);
        window.addEventListener('touchmove', killTweens);

        gsap.to(scrollAmount, {
            duration: .9,
            y: target,
            ease: 'power2.inOut',
            onUpdate,
            onStart: () => {
                window.Application.isScrollingTo = true;
            },
            onComplete: () => {
                ctx.scrollingTo = false;
                window.removeEventListener('wheel', killTweens);
                window.removeEventListener('touchmove', killTweens);
                window.Application.isScrollingTo = false;
                resolve();
            }
        });
    })
}
