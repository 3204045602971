<template>
    <section class="home-slider">
        <div class="home-slider__container">
            <ul ref="list" class="home-slider__list">
                <li ref="items" class="home-slider__item" :class="{ 'home-slider__item--nav': previousId === key || nextId === key }" v-for="(item, key) in slides" :key="key" @mouseenter="onHover(key)" @mouseleave="onHoverOut(key)" @click="onClick(key)">

                    <div class="home-slider__item-container">
                        <iframe v-if="item.media && item.media.type === 'video'" ref="iframe" :src="`${ item.media.static_url }?loop=1&background=1&autoplay=0&responsive=true`" :width="item.width" :height="item.height" class="home-slider__video" frameborder="0" allow="autoplay"></iframe>
                        <div v-else class="home-slider__image" :style="{ backgroundImage: `url('${ imageLoader(item.media, { width: 780 }) }')` }"></div>
                    </div>

                    <div class="home-slider__copy">
                        <h3 class="home-slider__title t-copy u-text-link" v-html="`<span class='u-arrow-link'>→</span>&nbsp;&nbsp;${ item.title }`"></h3>
                        <p class="home-slider__number t-small-text" v-html="`${ key + 1 }/${ slides.length }`"></p>
                    </div>

                    <transition name="fade-arrows">
                        <div class="home-slider__arrow" v-if="nextId === key"></div>
                    </transition>
                    <transition name="fade-arrows">
                        <div class="home-slider__arrow home-slider__arrow--right" v-if="previousId === key"></div>
                    </transition>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>

import { mapGetters } from 'vuex';
import axios from 'axios';
import Player from '@vimeo/player';
import gsap from 'gsap';
import EventBus from '../EventBus';
import OnScroll from '../utils/OnScroll';
import imageLoader from '../utils/imageLoader.js';
import SimpleSwipeController from '../utils/SimpleSwipeController';

export default {
    name: 'HomeSlider',

    props: {
        slides: Array
    },

    data() {
        return {
            selected: 0,
            scrolls: [],
            imageLoader
        };
    },

    computed: {
        ...mapGetters(['isMobile']),

        previousId() {
            return this.selected - 1;
        },

        nextId() {
            return this.isMobile ? this.selected + 1 : this.selected + 2;
        },

        offset() {
            return this.isMobile ? 12 : 20;
        }
    },

    watch: {
        isMobile() {
            this.reset();
        }
    },

    mounted() {
        this.placeVideos();
        this.videoControls();
        this.vimeoPlayers[0] && this.vimeoPlayers[0].play();

        if (this.isMobile) {
            this.navSwipeController = new SimpleSwipeController({ $el: this.$refs.list, trigger: this.onSwipe });
            this.navSwipeController.initialize();
        }

        this.prepareElements();

        EventBus.$on('app:resize', this.resize);
    },

    beforeDestroy() {
        EventBus.$off('app:resize', this.resize);
        this.navSwipeController && this.navSwipeController.terminate();

        this.scrolls.forEach((scroll) => {
            scroll.kill();
        });
    },

    methods: {
        reset() {
            if (this.isMobile) {
                if (!this.$refs.items || this.$refs.items.length < 2) return;

                this.scrolls.forEach((scroll) => {
                    scroll.kill();
                });

                this.scrolls = [];

                gsap.set([this.$refs.items[0], this.$refs.items[1], this.$refs.items[2]], {
                    clearProps: 'y'
                });
            } else {
                this.prepareElements();
            }
        },

        prepareElements() {
            if (this.isMobile) return;
            if (!this.$refs.items || this.$refs.items.length < 2) return;

            gsap.set(this.$refs.items[0], {
                y: 100,
                x: 180
            });

            gsap.set(this.$refs.items[1], {
                y: 150,
                x: 180
            });

            gsap.set(this.$refs.items[2], {
                y: 200,
                x: 180
            });

            gsap.delayedCall(.5, () => {
                this.scrolls.push(new OnScroll(this.$refs.items[0], {
                    y: 0,
                }, 'A'));

                this.scrolls.push(new OnScroll(this.$refs.items[1], {
                    y: 0,
                }, 'A'));

                this.scrolls.push(new OnScroll(this.$refs.items[2], {
                    y: 0,
                }, 'A'));
            });
        },

        placeVideos() {
            let videoCount = 0;
            this.slides.forEach((slide, i) => {
                if (slide.media.type === 'video') {
                    this.getDimensions(slide.media.static_url, i, videoCount);
                    videoCount++;
                }
            });
        },

        getDimensions(url, i, iframKey) {
            this.containerWidth = this.$refs.items[0].offsetWidth;
            this.containerHeight = this.$refs.items[0].offsetHeight;

            axios({
                method: 'get',
                url: `https://vimeo.com/api/oembed.json?url=${ url }`
            })
            .then((e) => {
                this.slides[i].width = e.data.width;
                this.slides[i].height = e.data.height;
                this.$forceUpdate();
                this.positionVideo(e.data.width, e.data.height, iframKey);
            });
        },

        positionVideo(width, height, iframKey) {
            const $iframe = this.$refs.iframe.length > 1 ? this.$refs.iframe[iframKey] : this.$refs.iframe[0];
            let ratio = width / height;
            const containerRatio = this.containerWidth / this.containerHeight;
            const alignByWidth = ratio < containerRatio;

            if (!alignByWidth) {
                $iframe.style.height = `${ this.containerHeight }px`;
                $iframe.style.width = `${ this.containerHeight * ratio }px`;
            } else {
                ratio = height / width;
                $iframe.style.width = `${ this.containerWidth }px`;
                $iframe.style.height = `${ this.containerWidth * ratio }px`;
            }
        },

        videoControls() {
            this.vimeoPlayers = [];

            this.slides.forEach((slide, i) => {
                if (slide.media.type === 'video') {
                    const player = new Player(this.$refs.items[i].querySelector('.home-slider__video'));
                    this.vimeoPlayers.push(player);
                } else {
                    this.vimeoPlayers.push('');
                }
            });
        },

        slide(direction) {
            this.sliding = true;

            const width = this.offset + this.$refs.items[0].offsetWidth;
            const x = direction === 1 ? width : -width;

            gsap.to(this.$refs.list, {
                duration: 1.25,
                x: `+=${ x }`,
                ease: 'power4.out',
                onComplete: () => {
                    this.sliding = false;
                }
            });
        },

        resize() {
            // Slides
            const x = -(this.offset + this.$refs.items[0].offsetWidth) * this.selected;
            gsap.set(this.$refs.list, { x });

            // Videos
            this.containerWidth = this.$refs.items[0].offsetWidth;
            this.containerHeight = this.$refs.items[0].offsetHeight;

            let videoCount = 0;

            this.slides.forEach((slide, i) => {
                if (slide.media.type === 'video') {
                    this.positionVideo(this.slides[i].width, this.slides[i].height, videoCount);
                    videoCount++;
                }
            });
        },

        onSwipe(dir) {
            if (this.sliding) return;

            if (dir === -1) {
                if (this.selected === this.slides.length - 1) return;
                this.selected += 1;
                this.slide(-1);
            } else {
                if (this.selected === 0) return;
                this.selected -= 1;
                this.slide(1);
            }
        },

        onClick(key) {
            if (this.sliding) return;

            if (key === this.nextId) {
                this.selected += 1;
                this.slide(-1);
            } else if (key === this.previousId) {
                this.selected -= 1;
                this.slide(1);
            } else {
                const slug = this.slides[key].slug;
                this.$router.push(slug);
            }
            this.onHover(key);
        },

        onHover(key) {
            if (key === this.previousId || key === this.nextId) return;

            if (this.slides[key].media.type === 'video') {
                this.vimeoPlayers[key].play();
            }
        },

        onHoverOut(key) {
            if (key === this.previousId || key === this.nextId) return;

            if (this.slides[key].media.type === 'video') {
                this.vimeoPlayers[key].pause();
            }
        }
    }
};
</script>
