<template>
    <div class="vimeo-video" :style="{ paddingBottom: `${ paddingBottom }%` }" @click="onPlayClick">

        <button ref="playBtn" class="vimeo-video__play-btn" v-if="!autoPlay">
            <img src="/img/icons/video-play.png" srcset="/img/icons/video-play@2x.png 2x" alt="Play">
            <span class="u-visually-hidden">play</span>
        </button>
        <iframe ref="iframe" :src="`${ url }?autopause=0&controls=0&autoplay=${ + Number(autoPlay) }&loop=${ + Number(loop) }&background=${ + Number(autoPlay) }&responsive=true`" class="vimeo-video__video" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
</template>

<script>

import Player from '@vimeo/player';
import axios from 'axios';
import gsap from 'gsap';

export default {
    name: 'VimeoVideo',

    props: {
        url: String,
        autoPlay: {
            type: Boolean,
            default: true
        },
        loop: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            isPlaying: false,
            paddingBottom: 0
        };
    },

    mounted() {
        this.getVideoData();
        this.player = new Player(this.$refs.iframe);
    },

    methods: {
        onPlayClick() {
            if (this.autoPlay) return;

            if (this.isPlaying) {
                this.pause();
            } else {
                this.play();
            }

            this.isPlaying = !this.isPlaying;
        },

        play() {
            this.player.play();

            gsap.to(this.$refs.playBtn, {
                opacity: 0,
                duration: .5,
                ease: 'power2.out',
            });
        },

        pause() {
            this.player.pause();

            gsap.to(this.$refs.playBtn, {
                opacity: 1,
                duration: .5,
                ease: 'power2.out',
            });
        },

        getVideoData() {
            axios({
                method: 'get',
                url: `https://vimeo.com/api/oembed.json?url=${ this.url }`
            })
            .then((e) => {
                this.positionVideo(e.data.width, e.data.height);
            });
        },

        positionVideo(width, height) {
            this.paddingBottom = (height / width) * 100;
        },
    }
};
</script>
