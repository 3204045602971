<template>
    <div>
        <div class="google-map" ref="googleMap"></div>
        <template v-if="!!this.google && !!this.map">
            <slot :google="google" :map="map" />
        </template>
    </div>
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";

export default {
    props: {
        mapConfig: Object,
        apiKey: String,
        zoomAddress: String,
        addMarker: Boolean
    },

    data() {
        return {
            google: null,
            map: null
        };
    },

    async mounted() {
        const googleMapApi = await GoogleMapsApiLoader({
            apiKey: this.apiKey
        });
        this.google = googleMapApi;
        this.initializeMap();
    },

    methods: {
        initializeMap() {

            const mapContainer = this.$refs.googleMap;

            if (this.zoomAddress) {
                const geocoder = new this.google.maps.Geocoder();
                
                geocoder.geocode({
                    address: this.zoomAddress
                }, (results, status) => {

                    if (status == 'OK') {

                        this.mapConfig.center = results[0].geometry.location;
                        this.map = new this.google.maps.Map(mapContainer, this.mapConfig);

                        if (this.addMarker) {
                            const marker = new this.google.maps.Marker({
                                position: results[0].geometry.location,
                                icon: '/img/oval.svg',
                                map: this.map
                            });
                        }
                    } else {
                        console.error('Address not found', this.zoomAddress);
                    }
                });

            } else {
                this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
            }
        }
    }
};
</script>
