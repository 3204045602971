import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

Vue.use(Vuex);

export default function (injectedState = {}) {
    const initialState = { ...state, ...injectedState };

    return new Vuex.Store({
        state: initialState,
        ...mutations,
        ...getters,
        ...actions,
        strict: process.env.NODE_ENV !== 'production'
    });
}
