<template>
    <div class="quote-block">
        <div class="quote-block__image" :style="{ backgroundImage: `url(${ authorImg }` }" v-if="!isMobile"></div>

        <blockquote class="quote-block__blockquote">
            <div class="quote-block__image" :style="{ backgroundImage: `url(${ authorImg }` }" v-if="isMobile"></div>
            <div class="quote-block__quote t-quote">“{{ content.quote }}”</div>
            <cite class="quote-block__cite t-small-text ">{{ content.quoteSubtitle }}</cite>
        </blockquote>
    </div>
</template>

<script>

import imageLoader from '../utils/imageLoader.js';

export default {
    name: 'QuoteBlock',

    props: {
        content: Object,
        isMobile: Boolean,
        isRetina: Boolean
    },

    computed: {
        authorImg() {
            const { author } = this.content;
            const multiplier = (this.isRetina && !this.isMobile) ? 2 : 1;

            return imageLoader(author[0], {
                width: 300 * multiplier,
                height: 300 * multiplier,
                fit: true
            });
        }
    }
};
</script>
