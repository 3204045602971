<template>
    <div id="wrapper">
        <Cookie v-if="showCookie" :content="general.cookie"/>

        <template v-if="!isEmptyLayout">
            <Intro v-if="showLogoIntro"/>
            <Nav v-if="!isMobile && !isTablet"/>
            <NavMobile v-else/>
        </template>

        <transition-group name="fade-router" @after-enter="onEnter" @after-leave="afterLeave" @leave="onLeave" appear mode="out-in">
            <Logo :key="`${ $route.path }-0`"/>

            <router-view :key="$route.path"></router-view>
        </transition-group>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import gsap from 'gsap';
import Cookies from 'js-cookie';
import EventBus from './EventBus';
import scrollTo from './utils/scrollTo.js';

import Logo from './components/logo/Logo.vue';
import Intro from './components/Intro.vue';
import Nav from './blocks/Nav.vue';
import NavMobile from './blocks/NavMobile.vue';
import Cookie from './blocks/Cookie.vue';

export default {
    name: 'App',

    components: {
        Logo,
        Intro,
        Nav,
        NavMobile,
        Cookie
    },

    watch: {
        isIntroFinished: {
            handler(to, from) {
                if (this.$route.hash && to) {
                    scrollTo(this.$route.hash);
                }
            },
            immediate: true
        },

        $route(to, from) {
            window.Application.routerUpdated = true;
            this.routeUpdated = true;
            this.previousRoute = from.name;
            this.nextRoute = to.name;

            this.setLogoIsIntro(to === 'home');
        }
    },

    computed: {
        ...mapGetters(['isMobile', 'isTablet', 'isIntroStarted', 'isIntroFinished', 'pages', 'logoIsIntro', 'general']),

        location() {
            return this.$route.name;
        },

        isEmptyLayout() {
            const locations = ['404'];
            return locations.indexOf(this.location) >= 0;
        },

        showIntro() {
            if (!this.isMobile && !this.isTablet) {
                if (this.$route.name === 'home') {
                    return true;
                }
            }
            return false;
        },

        showCookie() {
            return !Cookies.get('policy');
        },

        showLogoIntro() {
            // home and first load and desktop
            return this.$route.name === 'home' && !this.routeUpdated && !this.isMobile;
        }
    },

    created() {
        this.lastPage = this.$route.name;
        this.setLogoIsIntro(this.$route.name === 'home');
    },

    mounted() {
        gsap.ticker.add(this.tick);
        window.addEventListener('resize', this.resize);

        if (this.isMobile) {
            this.setIntroStarted(true);
        }

        if (this.$route.name !== 'home') {
            this.setIntroStarted(true);
        }
    },

    beforeDestroy() {
        gsap.ticker.remove(this.tick);
        window.removeEventListener('resize', this.resize);
    },

    methods: {
        ...mapActions(['setWindowWidth', 'setIntroStarted', 'setLogoIsIntro']),

        resize() {
            window.Application.wH = window.innerHeight;
            window.Application.wW = window.innerWidth;

            this.setWindowWidth(window.Application.wW);
            EventBus.$emit('app:resize');
        },

        tick() {
            window.Application.scroll = window.pageYOffset || document.documentElement.scrollTop;
            EventBus.$emit('app:tick');
        },

        onEnter() {
            if (this.routeUpdated) {
                this.resize();
            }

            gsap.delayedCall(.3, () => {
                this.resize();
            });
        },

        afterLeave() {
            if (this.$route.name === 'home' && window.Application.lastScroll) {
                window.scrollTo(0, window.Application.lastScroll);
            } else {
                window.scrollTo(0, 0);
            }
        },

        onLeave() {
            if (this.previousRoute === 'home') {
                window.Application.lastScroll = window.Application.scroll;
            }
        }
    }
};
</script>
