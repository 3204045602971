<template>
    <section class="video-section">
        <div class="video-section__container">
            <VimeoVideo ref="video" v-if="content.media.type === 'video'" class="video-section__iframeContainer" :url="content.media.static_url" />
            <img v-else class="video-section__image" :src="content.media.static_url" />

            <div ref="copy" class="video-section__copy" v-inview:enter="animateIn">
                <div ref="title" class="video-section__titles">
                    <h2 class="video-section__title t-heading" v-html="`${ content.title } — `"></h2>
                    <h2 class="video-section__location t-heading" v-html="content.city"></h2>
                </div>

                <FeatureParagraph class="video-section__body" :content="content" :max="80"/>
            </div>
        </div>
    </section>
</template>

<script>

import axios from 'axios';
import gsap from 'gsap';
import { mapActions, mapGetters } from 'vuex';
import FeatureParagraph from '../components/FeatureParagraph.vue';
import VimeoVideo from '../components/VimeoVideo.vue';
import SplitText from '../utils/SplitText';
import InView from '../directives/InView';
import OnScroll from '../utils/OnScroll';

export default {
    name: 'VideoSection',

    components: {
        FeatureParagraph,
        VimeoVideo
    },

    props: {
        content: Object
    },

    data() {
        return {
            scrolls: []
        };
    },

    computed: {
        ...mapGetters(['isMobile'])
    },

    watch: {
        isMobile() {
            this.reset();
        }
    },

    mounted() {
        this.prepareElements();
    },

    beforeDestroy() {
        this.scrolls.forEach((scroll) => {
            scroll.kill();
        });
    },

    methods: {
        reset() {
            if (this.isMobile) {
                this.scrolls.forEach((scroll) => {
                    scroll.kill();
                });

                this.scrolls = [];

                gsap.set([this.$refs.video.$el, this.$refs.copy], {
                    clearProps: 'y'
                });

                this.split && this.split.revert();

                if (this.$titleChars) {
                    gsap.to(this.$titleChars, {
                        duration: .7,
                        opacity: 1,
                        ease: 'power3.inOut',
                        stagger: {
                            each: 0.02,
                            ease: 'none'
                        }
                    });
                }
            } else {
                this.split && this.split.revert();
                this.prepareElements();

                if (this.$titleChars) {
                    gsap.to(this.$titleChars, {
                        duration: .7,
                        opacity: 1,
                        ease: 'power3.inOut',
                        stagger: {
                            each: 0.02,
                            ease: 'none'
                        }
                    });
                }
            }
        },

        prepareElements() {
            if (this.isMobile) return;

            // Title
            this.split = new SplitText(this.$refs.title, { type: 'chars', charsClass: 'split' });
            this.$titleChars = this.split.chars;

            gsap.set(this.$titleChars, {
                opacity: 0,
            });


            // Video
            gsap.set(this.$refs.video.$el, {
                y: 100,
            });

            this.scrolls.push(new OnScroll(this.$refs.video.$el, {
                y: -125,
            }, 'C'));


            // Copy
            gsap.set(this.$refs.copy, {
                y: 100,
                transformOrigin: 'center'
            });

            this.scrolls.push(new OnScroll(this.$refs.copy, {
                y: -50,
            }, 'A'));
        },

        animateIn() {
            if (this.isMobile) return;
            this.isInView = true;

            gsap.to(this.$titleChars, {
                duration: .7,
                opacity: 1,
                ease: 'power3.inOut',
                stagger: {
                    each: 0.02,
                    ease: 'none'
                }
            });
        }
    }
};
</script>
