// Load dependencies
import Vue from 'vue';
import gsap from 'gsap';
import VueRouter from 'vue-router';
import Home from './pages/Home.vue';
import Project from './pages/Project.vue';
import NotFound from './pages/Page404.vue';
import scrollTo from './utils/scrollTo.js';

Vue.use(VueRouter);

export default function ({ analyticsId }) {
    const router = new VueRouter({
        mode: 'history',

        scrollBehavior(to, from, savedPosition) {
            if (to.hash) {
                const isDifferentPage = to.name !== from.name;
                if (isDifferentPage) {
                    gsap.delayedCall(2, () => scrollTo(to.hash).then(() => ({ selector: to.hash })));
                } else {
                    return scrollTo(to.hash).then(() => ({ selector: to.hash }));
                }
            }
        },

        routes: [
            {
                path: '/',
                name: 'home',
                component: Home
            },
            {
                path: '/development/:slug',
                name: 'development',
                props: route => ({ ...route.params, type: 'development' }),
                component: Project
            },
            {
                path: '/investment/:slug',
                name: 'investment',
                props: route => ({ ...route.params, type: 'investment' }),
                component: Project
            },
            {
                path: '/*',
                name: '404',
                component: NotFound
            },
        ]
    });

    router.afterEach((to, from) => {
        const { name, path: page_path, params } = to;

        const isInternalNavigation = (from.name === name) && (name.toLowerCase() === 'home');
        if (isInternalNavigation) return;

        const page_title = params.slug ? `${ name }: ${ params.slug }` : name;

        if (typeof gtag === 'function') {
            gtag('config', analyticsId, {
                page_title,
                page_path,
                anonymize_ip: true
            });
        }
    });


    return router;
}

