<template>
    <div class="text-columns-block t-copy">
        <div class="text-columns-block__container" v-html="cleanContent"></div>
    </div>
</template>

<script>
export default {
    name: 'TextColumnsBlock',

    props: {
        content: Object
    },

    computed: {
        cleanContent() {
            return this.content.content.replace(/<p><br><\/p>/gi, '<br>');
        }
    }
}
</script>
