/*

// OnScroll
-> animates elements in view as you scroll.

// Modes:
A:
    . Starts: top in view
    . Stops: top half of view

B:
    . Starts: top in view
    . Stops: top out of view

C:
    . Starts: top in view
    . Stops: bottom out of view

// Usage:
Init:
    this.anim = new OnScroll(this.$refs.el, {
        rotation: 360,
        scale: 3
    }, 'A');

Kill:
    this.anim & this.anim.kill();

*/

import gsap from 'gsap';
import bindAll from './bindAll';
import EventBus from '../EventBus';

export default class OnScroll {
    constructor($el, tween, mode, once) {
        if (!$el) return;
        this.$el = $el;
        this.tween = tween;
        this.once = once;

        bindAll(this, ['onTick', 'animateA', 'animateB', 'getBounds', 'onResize']);
        this.init(mode);
    }

    init(mode) {
        this.tween.duration = 1;
        this.tl = new gsap.timeline({ paused: true })
        .to(this.$el, this.tween);

        this.callback = this.setCallback(mode);

        gsap.delayedCall(.2, () => {
            this.getBounds();

            EventBus.$on('app:resize', this.onResize);
            EventBus.$on('app:tick', this.onTick);
        });
    }

    setCallback(mode) {
        switch (mode) {
        case 'A': return this.animateA;
        case 'B': return this.animateB;
        case 'C': return this.animateC;
        default: return this.animateA;
        }
    }

    getBounds() {
        this.rect = this.$el.getBoundingClientRect();
        this.topDistance = this.rect.top + window.Application.scroll;
        this.height = this.$el.offsetHeight;
        this.scroll = 0;

        if (this.topDistance < window.Application.wH) {
            this.isInitiallyIn = true;
        }
    }

    onResize() {
        this.getBounds();
    }

    onTick() {
        if (this.scroll !== window.Application.scroll) {
            this.scroll = window.Application.scroll;
            this.callback();
        }
    }

    animateA() {
        const upTopDistance = this.topDistance - window.Application.scroll - (window.Application.wH / 2);
        this.beforeArea = upTopDistance > 0;
        this.afterArea = upTopDistance < (window.Application.wH / 2);
        this.windowScroll = upTopDistance / (window.Application.wH / 2);

        this.animate();
    }

    animateB() {
        const upTopDistance = this.topDistance - window.Application.scroll;
        this.beforeArea = upTopDistance > 0;
        this.afterArea = upTopDistance < window.Application.wH;
        this.windowScroll = upTopDistance / window.Application.wH;

        this.animate();
    }

    animateC() {
        const upTopDistance = this.topDistance - window.Application.scroll;
        this.beforeArea = upTopDistance + this.height > 0;
        this.afterArea = upTopDistance < window.Application.wH;
        this.windowScroll = (upTopDistance + this.height) / (window.Application.wH + this.height);

        if (this.isInitiallyIn) {
            this.afterArea = upTopDistance < this.topDistance;
            this.windowScroll = (upTopDistance + this.height) / (this.topDistance + this.height);
        }

        this.animate();
    }

    animate() {
        const inArea = this.beforeArea && this.afterArea;

        if (inArea) {
            this.tl.progress(-this.windowScroll + 1);
        } else if (this.afterArea) {
            this.tl.progress(1);
            this.once && this.kill();
        } else if (this.beforeArea) {
            this.tl.progress(0);
        }
    }

    kill() {
        EventBus.$off('app:resize', this.onResize);
        EventBus.$off('app:tick', this.onTick);
    }
}
