import siteMeta from '../meta/en/site_meta.json';

export default function updateMetaTitle(routeName, blockTitle) {
    const meta = siteMeta.filter(routeMeta => routeMeta.route_name === routeName);
    const dynamicMetaRoute = ['development', 'investment'].indexOf(routeName) >= 0;

    if (dynamicMetaRoute) {
        if (blockTitle) document.title = `Flow Real Estate - ${ blockTitle }`;
    } else {
        const title = meta.length ? meta[0].title : siteMeta[0].title;
        document.title = title;
    }
}
