<template>
    <div class="logo logo--real-estate">
        <svg ref="logo" class="logo__logo" height="150" viewBox="0 0 341 150" width="341" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
                <g fill="#231f20">
                    <path d="m51.566.9682-14.939 7.405-11.943-7.405h-4.098l15.925 9.874 19.919-9.874z"/>
                    <path d="m72.9384.9682-36.315 16.365-29.034-16.365h-4.399l33.324 18.783 41.681-18.783z"/>
                    <path d="m99.0547.9682-62.435 25.322-36.051-18.287v2.422l35.951 18.237 68.283-27.694z"/>
                    <path d="m36.6147 35.2474-36.046-16.254v2.37l35.955 16.212 72.045-25.973v-2.296z"/>
                    <path d="m36.6093 44.2006-36.04-14.22v2.322l35.603 14.047.356.141 72.041-22.725v-2.265z"/>
                    <path d="m36.6039 53.152-36.035-12.187v2.281l35.654 12.057.311.105 72.035-19.477v-2.237z"/>
                    <path d="m36.5994 62.1007-36.031-10.154v2.244l35.971 10.137 72.029-16.229v-2.214z"/>
                    <path d="m36.5931 71.0476-36.024-8.122v2.214l35.976 8.111 72.024-12.982v-2.195z"/>
                    <path d="m36.5877 79.9918-36.019-6.091v2.191l35.982 6.084 72.018-9.736v-2.179z"/>
                    <path d="m36.5814 88.9333-36.013-4.06v2.174l35.88 4.044.109.013 72.011-6.491v-2.168z"/>
                    <path d="m36.576 97.8712-36.007-2.029v2.163l35.993 2.029 72.007-3.245v-2.162z"/>
                    <path d="m.569 108.968h108v-2.161h-108z"/>
                </g>

                <g ref="logoText" class="logo__logoText">
                    <path d="m135.8617 81.5732h15.682v5.266h-9.746v7.318h9.746v5.15h-9.746v9.37h-5.936z" fill="#030003"/>
                    <path d="m155.682 81.5732h5.936v21.528h9.526v5.576h-15.462z" fill="#030003"/>

                    <g fill="#030003" transform="translate(135 80.758)">
                        <path d="m59.0052 14.367c0-4.724-2.638-8.712-7.511-8.712-4.91 0-7.548 3.988-7.511 8.712-.037 4.724 2.601 8.712 7.511 8.712 4.873 0 7.511-3.988 7.511-8.712m-21.214 0c0-7.705 5.093-14.326 13.703-14.326 8.611 0 13.703 6.621 13.703 14.326 0 7.706-5.092 14.327-13.703 14.327-8.61 0-13.703-6.621-13.703-14.327"/>
                        <path d="m65.305.8152h6.229l5.349 18.005 5.094-18.005h4.616l5.093 18.005 5.349-18.005h6.229l-9.05 27.104h-4.837l-5.093-17.037-5.092 17.037h-4.836z"/>
                        <path d="m3.2425 57.3841h6.339c3.517 0 6.082-2.904 6.082-6.854 0-3.949-2.565-6.853-6.082-6.853h-6.339zm9.16 1.975 5.423 9.06h-2.858l-5.129-8.635h-.148-6.448v8.635h-2.491v-27.104h8.939c4.91 0 8.464 3.872 8.464 9.215 0 4.337-2.308 7.667-5.752 8.829z"/>
                        <path d="m22.9164 41.3152h14.363v2.362h-11.872v10.067h11.872v2.401h-11.872v9.873h11.872v2.401h-14.363z"/>
                        <path d="m55.3773 61.1785-4.982-16.34-4.947 16.34zm.696 2.362h-11.358l-1.465 4.879h-2.675l8.72-27.104h2.235l8.72 27.104h-2.674z"/>
                        <path d="m64.0961 41.3152h2.491v24.626h11.542v2.478h-14.033z"/>
                        <path d="m92.1234 41.3152h14.362v2.362h-11.871v10.067h11.871v2.401h-11.871v9.873h11.871v2.401h-14.362z"/>
                        <path d="m110.515 60.0174h2.491c0 4.065 2.565 6.66 6.668 6.66 3.701 0 5.826-1.936 5.826-5.15 0-2.672-1.428-4.298-4.433-5.189l-4.323-1.394c-3.627-1.045-5.46-3.368-5.46-7.047 0-4.259 3.224-7.163 7.988-7.163 4.982 0 8.17 3.098 8.17 8.132h-2.491c0-3.718-2.125-5.848-5.752-5.848-3.298 0-5.423 1.898-5.423 4.725 0 2.671 1.538 4.297 4.799 5.265l4.177 1.317c3.371 1.006 5.202 3.407 5.202 7.125 0 4.607-3.187 7.549-8.28 7.549-5.679 0-9.159-3.407-9.159-8.982"/>
                        <path d="m137.6996 43.7933h-7.805v-2.478h18.137v2.478h-7.841v24.626h-2.491z"/>
                        <path d="m163.8588 61.1785-4.983-16.34-4.947 16.34zm.695 2.362h-11.357l-1.466 4.879h-2.675l8.721-27.104h2.234l8.721 27.104h-2.675z"/>
                        <path d="m177.5599 43.7933h-7.804v-2.478h18.137v2.478h-7.842v24.626h-2.491z"/>
                        <path d="m191.2982 41.3152h14.362v2.362h-11.871v10.067h11.871v2.401h-11.871v9.873h11.871v2.401h-14.362z"/>
                    </g>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import gsap from 'gsap';
import EventBus from '../../EventBus';

export default {
    name: 'LogoRealEstate',

    mounted() {
        this.animateIn();
    },

    methods: {
        animateIn() {
            const delay = !this.logoIsIntro ? 0 : 2.5;

            if (this.$refs.logo) {
                gsap.set(this.$refs.logo, {
                    opacity: 0
                });

                gsap.to(this.$refs.logo, {
                    duration: 1,
                    opacity: 1,
                    delay,
                    ease: 'linear.none'
                });
            }
        }
    }
};
</script>
