<template>
    <div class="address-copy">
        <div class="address-copy__container">
            <div class="address-copy__top u-opacity-animate" v-inview="visible">
                <ul class="address-copy__address">
                    <li class="t-white t-small-text" v-for="(item, i) in content.address" :key="i" v-html="item"></li>
                </ul>
                <p class="address-copy__copy t-copy t-white" v-html="content.copy"></p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'AddressCopyBlock',

    props: {
        content: Object,
    },

    data() {
        return {
            visible: 'visible'
        };
    },

    computed: {
        ...mapGetters(['isMobile'])
    }
};

</script>
