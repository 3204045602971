<template>
    <nav class="nav">
        <transition-group name="fade-nav" appear mode="out-in">
            <ul ref="menu" v-if="showNav" class="nav__menu" :class="{ 'nav__menu--white': isHoveringBlack }" key="0">
                <li class="nav__item" :class="{ 'nav__item--active': active === key}" v-for="(item, key) in general.menu" :key="key">
                    <router-link ref="navItems" class="t-nav" :to="{ name: 'home', hash: item.target }" v-html="item.copy" @click.native="onClick(key)"></router-link>
                </li>
            </ul>

            <router-link v-else ref="close" to="/" class="t-copy nav__close" key="1">
                <svg class="nav__cross" height="13" viewBox="0 0 13 13" width="13" xmlns="http://www.w3.org/2000/svg"><path d="m1266.718 93.156 1.705-1.705-4.185-4.216 4.216-4.216-1.705-1.674-4.216 4.216-4.216-4.216-1.674 1.705 4.185 4.185-4.185 4.216 1.705 1.674 4.185-4.185z" fill="#ffffff" transform="translate(-1256 -81)"/></svg>{{ general.close }}
            </router-link>
        </transition-group>
    </nav>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import gsap from 'gsap';
import SplitText from '../utils/SplitText';
import EventBus from '../EventBus';

export default {
    name: 'Nav',

    data() {
        return {
            active: -1,
            isHoveringBlack: false
        };
    },

    computed: {
        ...mapGetters(['general', 'pages', 'logoIsIntro']),

        showNav() {
            return this.$route.name === 'home';
        }
    },

    watch: {
        pages() {
            this.reset();
        },

        $route() {
            this.reset();
        }
    },

    beforeDestroy() {
        EventBus.$off('app:tick', this.tick);
        EventBus.$off('app:resize', this.resize);
    },

    mounted() {
        this.animateIn();

        if (!this.showNav) return;

        this.cacheTargets();
        gsap.delayedCall(1.1, () => {
            this.cacheTargets();
        });

        EventBus.$on('app:tick', this.tick);
        EventBus.$on('app:resize', this.resize);
    },

    methods: {
        reset() {
            if (this.$route.name === 'home') {
                gsap.delayedCall(1, () => {
                    if (!this.showNav) return;

                    this.cacheTargets();
                    gsap.delayedCall(1.1, () => {
                        this.cacheTargets();
                    });
                });
            }
        },

        animateIn() {
            const delay = !this.logoIsIntro ? 0 : 2.5;

            // Nav / Close
            const isProject = this.$route.name === 'development' || this.$route.name === 'investment';
            const $el = isProject ? this.$refs.close.$el : this.$refs.navItems.map(ref => ref.$el);
            const split = new SplitText($el, { type: 'chars', charsClass: 'split' });

            gsap.set(split.chars, {
                opacity: 0,
                x: 20
            });

            gsap.to(split.chars, {
                duration: .4,
                opacity: 1,
                x: 0,
                delay,
                stagger: 0.0075,
                ease: 'power4.easeInOut'
            });
        },

        cacheTargets() {
            if (!this.$refs.menu) return;

            this.navTargets = [];
            this.general.menu.forEach((item) => {
                const $target = document.querySelector(item.target);
                if (!$target) return;
                const target = $target.getBoundingClientRect().top + window.Application.scroll;
                this.navTargets.push(target);
            });

            this.navTop = (this.$refs.menu.offsetHeight / 2) + this.$refs.menu.getBoundingClientRect().top;
        },

        resize() {
            this.cacheTargets();
        },

        onClick(key) {
            this.active = key;
        },

        tick() {
            if (window.Application.isScrollingTo) return;


            if (this.$route.hash) {
                this.$router.push(this.$route.path);
            }

            // Update active item
            const scroll = window.Application.scroll + 10;

            this.navTargets.forEach((navTarget, i) => {
                if (scroll + 100 > navTarget && this.navTargets[i + 1] && this.navTargets[i + 1] > scroll + 100 && this.active !== i) {
                    this.active = i;
                } else if (scroll + 100 > navTarget && !this.navTargets[i + 1] && this.active !== i) {
                    this.active = i;
                } else if (scroll + 100 < navTarget && this.active !== -1 && i === 0) {
                    this.active = -1;
                }
            });
        }
    }
};
</script>
