const state = {
    // ui
    windowWidth: '',
    isRetina: '',
    isIntroStarted: false,
    isIntroFinished: false,
    logoIsIntro: true,
    general: {},
    pages: {},
    projects: {
        investment: {},
        development: {}
    }
};

export default state;
