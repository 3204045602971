<template>
    <div class="specs-block">
        <div class="specs-block__img-wrap">
            <div class="specs-block__img" :style="{ backgroundImage: `url(${ image })`}"></div>
        </div>

        <div class="specs-block__specs">
            <div class="specs-block__spec t-small-text" v-for="(title, i) in specsTitles" :key="i">
                <div class="specs-block__title">{{ content[title] }}</div>
                <div class="specs-block__desc t-copy" v-html="content[`specValue${ i + 1 }`]"></div>
            </div>
        </div>
    </div>
</template>

<script>

import imageLoader from '../utils/imageLoader.js';

export default {
    name: 'SpecsBlock',

    props: {
        content: Object,
        isMobile: Boolean,
        isRetina: Boolean
    },

    computed: {
        image() {
            // imageLoader
            const { image } = this.content;
            const multiplier = (this.isRetina && !this.isMobile) ? 2 : 1;

            return imageLoader(image[0], {
                width: 790 * multiplier
            });
        },

        specsTitles() {
            const maxSpecs = ['specTitle1', 'specTitle2', 'specTitle3', 'specTitle4', 'specTitle5'];
            return maxSpecs.filter(spec => this.content[spec]);
        }
    }
};
</script>
