<template>
    <div class="intro" :class="{ 'intro--align-top': alignTop, 'intro--home': $route.name === 'home' }"></div>
</template>

<script>

import gsap from 'gsap';
import lottie from 'lottie-web';
import { mapActions, mapGetters } from 'vuex';
import EventBus from '../EventBus';
import * as animationData from '../json/intro.json';

export default {
    name: 'Intro',

    components: {
        lottie
    },

    data() {
        return {
            alignTop: false
        };
    },

    computed: {
        ...mapGetters(['isMobile', 'isIntroStarted'])
    },

    watch: {
        $route(to) {
            if (to.hash || !this.$flow) return;

            gsap.to([this.$flow, this.$realEstate], {
                duration: to.name === 'home' ? .5 : 0,
                opacity: to.name === 'home' ? 1 : 0,
                delay: to.name === 'home' ? .5 : 0,
                ease: 'linear.none'
            });
        }
    },

    mounted() {
        if (this.isIntroStarted) {
            return;
        }

        this.animateSvg();
        this.cacheTargets();
        this.positionSvg();

        EventBus.$on('app:resize', this.resize);
    },

    beforeDestroy() {
        this.$lines && this.$lines.removeEventListener('click', this.onLogoClick);
        EventBus.$off('app:resize', this.resize);
    },

    methods: {
        ...mapActions(['setIntroStarted', 'setIntroFinished']),

        animateSvg() {
            window.Application.introAnimating = true;

            this.anim = lottie.loadAnimation({
                container: this.$el,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: animationData.default,
                rendererSettings: {
                    viewBoxOnly: true,
                    className: 'intro__svg'
                }
            });

            gsap.delayedCall(.5, () => {
                this.anim.play();
                this.setIntroStarted(true);
            });

            gsap.delayedCall(3, () => {
                this.introFinished = true;
                this.setIntroFinished(true);
            });

            this.anim.addEventListener('complete', () => {
                this.listenLogoEvents();
            });
        },

        listenLogoEvents() {
            const $logo = this.anim.renderer.layerElement;
            $logo.classList.add('svg-logo');
            const $groups = $logo.querySelectorAll('.svg-logo > g');
            this.$lines = $groups[0];
            this.$flow = $groups[2];
            this.$realEstate = $groups[1];


            this.$lines.classList.add('intro__lines');
            this.$lines.addEventListener('click', this.onLogoClick);
        },

        onLogoClick() {
            this.$router.push('/');
        },

        cacheTargets() {
            this.$svg = this.anim.renderer.svgElement;
            this.width = this.$el.offsetWidth;
            this.height = this.$el.offsetHeight;
            this.svgHeight = this.width / (16 / 9);
            this.alignTop = (this.width / this.height) < 1.2;
            this.heightAmount = this.alignTop ? 0 : -(this.height - this.svgHeight);

            this.big = window.Application.wW > 1100;
            this.veryBig = window.Application.wW > 1750;

            this.transform = this.veryBig ? 2070 / this.width : 1440 / this.width;
            this.x = this.big ? 0 : -66;
            this.y = this.big ? this.heightAmount - 34 : this.heightAmount - 61;
            this.y = this.veryBig ? this.y - 20 : this.y;
        },

        positionSvg() {
            gsap.to(this.$svg, {
                duration: .5,
                delay: 2,
                scale: this.transform,
                transformOrigin: 'top left',
                x: this.x,
                y: this.y,
                onComplete: this.resize
            });
        },

        resize() {
            if (!this.introFinished) return;
            this.cacheTargets();

            gsap.set(this.$svg, {
                scale: this.transform,
                transformOrigin: 'top left',
                x: this.x,
                y: this.y
            });
        }
    }
};
</script>
