
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_IS_RETINA = 'SET_IS_RETINA';
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH';
export const SET_IS_INTRO_STARTED = 'SET_IS_INTRO_STARTED';
export const SET_IS_INTRO_FINISHED = 'SET_IS_INTRO_FINISHED';
export const SET_LOGO_IS_INTRO = 'SET_LOGO_IS_INTRO';

//  data
export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_HOME = 'ADD_HOME';
